import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { colors } from "../../../constants/colors";
import {
  CheckBoxActiveIcon,
  CheckBoxIcon,
  CloseIcon,
  TypeIcon,
} from "../../../constants/icons";
import { BaseMultipleActiveInput } from "../../../ui";
import "./HeaderLayerType.css";

const HeaderLayerType = ({
  applyHandler,
  allPropertyTypes,
  setAllPropertyTypes,
  setFilters,
  filters,
  isPopUpShowType,
  setIsPopUpShowType,
  setIsPopUpShowLoc,
  residentialSubType,
  setResidentialSubType,
  foundResidentialSubType,
  setFoundResidentialSubType,
  allSubPropertyTypes,
  setAllSubPropertyTypes,
  setIsPopUpShowSpecs,
  setIsPopUpShowPrice,
}) => {
  const [applied, setApplied] = useState(null);
  const { isOpenNarrowFilters } = useSelector((state) => state.isResetFilters);

  useEffect(() => {
    let counter = null;
    if (residentialSubType?.length > 0) {
      counter++;
    }
    if (allPropertyTypes?.length > 0) {
      allPropertyTypes.forEach((type) => {
        if (type.PropertyType !== "Residential" && type.show === true) {
          counter++;
        }
      });
    }
    setApplied(counter);
  }, [isPopUpShowType, isOpenNarrowFilters]);

  const headerLayerTypeHandler = () => {
    setIsPopUpShowType(true);
    setIsPopUpShowLoc(false);
    setIsPopUpShowSpecs(false);
    setIsPopUpShowPrice(false);
  };

  const headerLayerPopupHandler = () => {
    setIsPopUpShowType(false);
  };

  return (
    <div className="HeaderLayerType-wrapper">
      <div
        className="HeaderLayerType-wrapper-content"
        onClick={headerLayerTypeHandler}
      >
        <div className="HeaderLayerType-wrapper-content-title">Type</div>
        <div className="HeaderLayerType-wrapper-content-title-icon">
          <div>
            <TypeIcon />
          </div>
        </div>
        {applied && (
          <div className="HeaderLayerType-wrapper-content-box">
            <div>{applied}</div>
          </div>
        )}
        {/* <div className="HeaderLayerType-wrapper-content-box">
          {applied ? (
            `${applied} Applied`
          ) : (
            <span className="HeaderLayerType-wrapper-content-box-none">
              None
            </span>
          )}
        </div> */}
      </div>
      {isPopUpShowType && (
        <TypePopup
          headerLayerPopupHandler={headerLayerPopupHandler}
          applyHandler={applyHandler}
          allPropertyTypes={allPropertyTypes}
          setAllPropertyTypes={setAllPropertyTypes}
          setFilters={setFilters}
          filters={filters}
          residentialSubType={residentialSubType}
          setResidentialSubType={setResidentialSubType}
          foundResidentialSubType={foundResidentialSubType}
          setFoundResidentialSubType={setFoundResidentialSubType}
          allSubPropertyTypes={allSubPropertyTypes}
          setAllSubPropertyTypes={setAllSubPropertyTypes}
        />
      )}
    </div>
  );
};

export default HeaderLayerType;

const TypePopup = ({
  headerLayerPopupHandler,
  applyHandler,
  allPropertyTypes,
  setAllPropertyTypes,
  setFilters,
  filters,
  residentialSubType,
  setResidentialSubType,
  foundResidentialSubType,
  setFoundResidentialSubType,
  allSubPropertyTypes,
  setAllSubPropertyTypes,
}) => {
  const resetTypeHandler = () => {
    setResidentialSubType(null);
    const temp = allPropertyTypes?.map((type) => {
      if (type?.PropertyType === "Residential") {
        return { ...type, show: true };
      } else return { ...type, show: false };
    });
    setAllPropertyTypes(temp);
  };

  const [allSubPropertyTypesTemp, setAllSubPropertyTypesTemp] = useState(null);

  useEffect(() => {
    axios
      .get(`https://api.realtytexas.net/v1/property-type/cognitive-search`)
      .then(function (response) {
        let temp2 = response.data.data["property-type-groups"]?.map((type) => {
          if (type.PropertyType === "Residential") {
            return {
              ...type,
              show: true,
              value: type.PropertySubType,
              label: type.PropertySubTypeDisplay,
            };
          } else
            return {
              ...type,
              show: false,
              value: type.PropertySubType,
              label: type.PropertySubTypeDisplay,
            };
        });
        setAllSubPropertyTypesTemp(
          temp2.sort((a, b) =>
            a.PropertySubType.localeCompare(b.PropertySubType)
          )
        );
      })
      .catch(function (error) {
        console.log(error, "POST ERROR");
      });
  }, []);

  return (
    <div className="TypePopup-wrapper">
      <div className="TypePopup-wrapper-box">
        <div className="TypePopup-wrapper-header-wrapper">
          Property Type Filters
        </div>
        <div
          className="TypePopup-wrapper-header-close"
          onClick={headerLayerPopupHandler}
        >
          <CloseIcon color={colors.mainBg} />
        </div>
        <div className="TypePopup-wrapper-header-content-block">
          <TypePopupPropertyType
            allPropertyTypes={allPropertyTypes}
            setAllPropertyTypes={setAllPropertyTypes}
            setFilters={setFilters}
            filters={filters}
            residentialSubType={residentialSubType}
            setResidentialSubType={setResidentialSubType}
          />
          <TypePopupPropertySubType
            residentialSubType={residentialSubType}
            setResidentialSubType={setResidentialSubType}
            foundResidentialSubType={foundResidentialSubType}
            setFoundResidentialSubType={setFoundResidentialSubType}
            allPropertyTypes={allPropertyTypes}
            allSubPropertyTypes={allSubPropertyTypes}
            allSubPropertyTypesTemp={allSubPropertyTypesTemp}
            setAllPropertyTypes={setAllPropertyTypes}
          />
        </div>
        <div className="TypePopup-wrapper-header-btns-block">
          <TypeReset
            resetTypeHandler={resetTypeHandler}
            headerLayerPopupHandler={headerLayerPopupHandler}
          />
          <TypeApply
            applyHandler={applyHandler}
            headerLayerPopupHandler={headerLayerPopupHandler}
          />
        </div>
      </div>
    </div>
  );
};

const TypePopupPropertyType = ({
  allPropertyTypes,
  setAllPropertyTypes,
  setFilters,
  filters,
  residentialSubType,
  setResidentialSubType,
}) => {
  const propertiesHandler = (currentType) => {
    if (residentialSubType) {
      setResidentialSubType(null);
      setFilters({
        ...filters,
        propertySubType: null,
      });
    }
    const temp = allPropertyTypes.map((type) => {
      if (type?.PropertyType === currentType?.PropertyType) {
        return { ...type, show: true };
      } else return { ...type, show: false };
    });

    setAllPropertyTypes(temp);
  };
  return (
    <div className="filters-block-filter-filter-location-content">
      <div>
        <p>Property Types</p>
      </div>
      <div className="filters-block-filter-PropertyType-content-wrapper">
        {allPropertyTypes &&
          allPropertyTypes.map((propertyType, i) => {
            return (
              <div
                className="filters-block-filter-PropertyType-content"
                key={i}
              >
                <div className="filters-block-filter-PropertyType-content-checkbox">
                  <div
                    className="filters-block-filter-PropertyType-content-checkbox-0"
                    onClick={() => propertiesHandler(propertyType)}
                  >
                    {propertyType?.show ? (
                      <CheckBoxActiveIcon />
                    ) : (
                      <CheckBoxIcon />
                    )}
                  </div>
                  <div>
                    <p
                      style={{ color: propertyType?.show ? "var(--blue)" : "" }}
                    >
                      {propertyType?.PropertyTypeDisplay}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const TypePopupPropertySubType = ({
  residentialSubType,
  setResidentialSubType,
  foundResidentialSubType,
  setFoundResidentialSubType,
  allPropertyTypes,
  allSubPropertyTypes,
  allSubPropertyTypesTemp,
}) => {
  const [currentSubPropertyTypeList, setCurrentSubPropertyTypeList] = useState(
    []
  );

  useEffect(() => {
    if (allPropertyTypes?.length > 0 && allSubPropertyTypesTemp?.length > 0) {
      let currentPropertyType = allPropertyTypes.filter((type) => type.show)[0];
      setCurrentSubPropertyTypeList(
        allSubPropertyTypesTemp?.filter(
          (subType) => subType.PropertyType === currentPropertyType.PropertyType
        )
      );
    }
  }, [allPropertyTypes, allSubPropertyTypesTemp]);

  return (
    <div className="filters-block-filter-filter-location-content">
      <div>
        <p>Property Types</p>
      </div>
      <div id="filters-block-filter-PropertySubType-content">
        <BaseMultipleActiveInput
          name="propertySubType"
          list={currentSubPropertyTypeList}
          placeholder={
            currentSubPropertyTypeList?.length
              ? `Select ${currentSubPropertyTypeList[0].PropertyTypeDisplay} Sub Type`
              : "None"
          }
          listTop="48px"
          item={residentialSubType ? residentialSubType : []}
          setItem={setResidentialSubType}
          found={
            residentialSubType
              ? foundResidentialSubType.filter(
                  (obj1) => !residentialSubType.some((obj2) => obj1 === obj2)
                )
              : foundResidentialSubType
          }
          setFound={setFoundResidentialSubType}
          field="label"
          height={50}
          stringValue={true}
        />
      </div>
    </div>
  );
};

const TypeApply = ({ applyHandler, headerLayerPopupHandler }) => {
  const TypeApplyHandler = () => {
    applyHandler();
    headerLayerPopupHandler();
  };

  return (
    <div className="TypeApply-wrapper" onClick={TypeApplyHandler}>
      <div className="TypeApply-apply-wrapper">
        <div>Apply</div>
      </div>
    </div>
  );
};

const TypeReset = ({ headerLayerPopupHandler, resetTypeHandler }) => {
  const TypeResetHandler = () => {
    resetTypeHandler();
    // headerLayerPopupHandler();
  };

  return (
    <div className="TypeReset-wrapper" onClick={TypeResetHandler}>
      <div className="TypeReset-apply-wrapper">
        <div>Clear</div>
      </div>
    </div>
  );
};
