import React, { createContext, useContext, useMemo, useState } from "react";

export const mokedListMinMaxPrices = [
  { value: 75000, label: "$75,000" },
  { value: 100000, label: "$100,000" },
  { value: 125000, label: "$125,000" },
  { value: 150000, label: "$150,000" },
  { value: 175000, label: "$175,000" },
  { value: 200000, label: "$200,000" },
  { value: 225000, label: "$225,000" },
  { value: 250000, label: "$250,000" },
  { value: 300000, label: "$300,000" },
  { value: 350000, label: "$350,000" },
  { value: 400000, label: "$400,000" },
  { value: 450000, label: "$450,000" },
  { value: 500000, label: "$500,000" },
  { value: 550000, label: "$550,000" },
  { value: 600000, label: "$600,000" },
  { value: 650000, label: "$650,000" },
  { value: 700000, label: "$700,000" },
  { value: 750000, label: "$750,000" },
  { value: 800000, label: "$800,000" },
  { value: 850000, label: "$850,000" },
  { value: 900000, label: "$900,000" },
  { value: 950000, label: "$950,000" },
  { value: 1000000, label: "$1,000,000" },
  { value: 2000000, label: "$2,000,000" },
  { value: 3000000, label: "$3,000,000" },
  { value: 4000000, label: "$4,000,000" },
  { value: 5000000, label: "$5,000,000" },
  { value: 6000000, label: "$6,000,000" },
  { value: 7000000, label: "$7,000,000" },
  { value: 8000000, label: "$8,000,000" },
  { value: 9000000, label: "$9,000,000" },
  { value: 10000000, label: "$10,000,000" },
  { value: 15000000, label: "$15,000,000" },
  { value: 20000000, label: "$20,000,000" },
  { value: 25000000, label: "$25,000,000" },
  { value: 30000000, label: "$30,000,000" },
  { value: 35000000, label: "$35,000,000" },
  { value: 40000000, label: "$40,000,000" },
  { value: 45000000, label: "$45,000,000" },
  { value: 50000000, label: "$50,000,000" },
];

const PriceMinMaxContext = createContext();

export const usePriceMinMaxData = () => {
  const context = useContext(PriceMinMaxContext);
  if (context === undefined) {
    throw new Error("useCurrentScreen must be used within a ValueProvider");
  }
  return context;
};

const PriceMinMaxProvider = ({ children }) => {
  const [minValues, setMinValues] = useState(mokedListMinMaxPrices);
  const [maxValues, setMaxValues] = useState(mokedListMinMaxPrices);

  const [minValue, setMinValue] = useState(mokedListMinMaxPrices[0]?.value);
  const [maxValue, setMaxValue] = useState(
    mokedListMinMaxPrices[mokedListMinMaxPrices.length - 1]?.value
  );

  const [minMaxPriceMin, setMinMaxPriceMin] = useState({
    value: null,
    label: "$0",
  });
  const [minMaxPriceMax, setMinMaxPriceMax] = useState({
    value: null,
    label: "$50,000,000",
  });

  const [sliderValues, setSliderValues] = useState([0, 1000000]);
  const [sliderDefaultValues, setSliderDefaultValues] = useState([0, 9900000]);
  const [stepValue, setStepValue] = useState(1);
  const [propertyTypePrice, setPropertyTypePrice] = useState([]);
  const [priceValue, setPriceValue] = useState([0, 50000000]);

  const valueObject = useMemo(() => {
    return {
      minValues,
      setMinValues,
      maxValues,
      setMaxValues,
      minValue,
      setMinValue,
      maxValue,
      setMaxValue,
      minMaxPriceMin,
      setMinMaxPriceMin,
      minMaxPriceMax,
      setMinMaxPriceMax,
      sliderValues,
      setSliderValues,
      sliderDefaultValues,
      setSliderDefaultValues,
      stepValue,
      setStepValue,
      propertyTypePrice,
      setPropertyTypePrice,
      priceValue,
      setPriceValue,
    };
  }, [
    minValues,
    maxValues,
    minValue,
    maxValue,
    minMaxPriceMin,
    minMaxPriceMax,
    sliderValues,
    sliderDefaultValues,
    stepValue,
    propertyTypePrice,
    priceValue,
  ]);
  return (
    <PriceMinMaxContext.Provider value={valueObject}>
      {children}
    </PriceMinMaxContext.Provider>
  );
};
export default PriceMinMaxProvider;
