import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HeaderLayerLocation,
  HeaderLayerSpecs,
  HeaderLayerType,
  SortingInputBlock,
  TooltipMessage,
} from "../../components";
import HeaderLayerPrice from "../../components/HeaderLayerBlock/HeaderLayerPrice/HeaderLayerPrice";
import { colors } from "../../constants/colors";
import {
  CubeIconBig4,
  FiltersIcon,
  Gallery4Icon,
  MapIcon,
} from "../../constants/icons";
import { getFilterLocationCities } from "../../store/actions/getFilterLocationCities";
import { updateIsOpenNarrowFilters } from "../../store/actions/updateIsOpenNarrowFilters";
import { HeaderMultipleInput } from "../../ui";
import TripleSwitcherSliderButton from "../../ui/TripleToggleViewButton/TripleSwitcherSliderButton";
import "./MainHeaderLayer.css";
import "./responsive.css";

const MainHeaderLayer = ({
  children,
  state,
  setState,
  getSwitchAnimation,
  isAllOurListings,
  setIsAllOurListings,
  filters,
  sortingValue,
  setSortingValue,
  currentPage,
  setCurrentPage,
  dataInRectPage,
  setDataInRectPage,
  isDraw,
  isMultyDraw,
  dataInPolyline,
  setDataInPolyline,
  dataInMultyPolygones,
  setDataInMultyPolygones,
  drawCoordinates,
  setDrawCoordinates,
  visibleHouseCards,
  city,
  setCity,
  foundCity,
  setFoundCity,
  country,
  setCountry,
  twCities,
  setTwCitiesPage,
  twCitiesPage,
  applyHandler,
  filterLocationCounties,
  foundCountry,
  setFoundCountry,
  metro,
  twPostals,
  setTwPostals,
  postal,
  setPostal,
  foundPostal,
  setFoundPostal,
  setTwPostalsPage,
  allPropertyTypes,
  setAllPropertyTypes,
  setFilters,
  residentialSubType,
  setResidentialSubType,
  foundResidentialSubType,
  setFoundResidentialSubType,
  bathMax,
  setBathMax,
  bathMin,
  setBathMin,
  levelsMin,
  setLevelsMin,
  levelsMax,
  setLevelsMax,
  bedsMin,
  setBedsMin,
  bedsMax,
  setBedsMax,
  sqftMin,
  setSqftMin,
  sqftMax,
  setSqftMax,
  garagesSpacesMin,
  setGaragesSpacesMin,
  garagesSpacesMax,
  setGaragesSpacesMax,
  isOurListings,
  setIsOurListings,
  mapZoom,
  setMapZoom,
  priceInfoLoading,
  setPriceInfoLoading,
}) => {
  const isMap = useSelector((state) => state.isMap);
  const { data } = useSelector((state) => state.houseCards);

  const dispatch = useDispatch();

  const openNarrowFiltersHandle = () => {
    dispatch(updateIsOpenNarrowFilters(true));
    window.scrollTo(0, 0);
  };

  const onMapHandle = () => {
    setState({ switchPosition: "center", animation: "left-to-center" });
  };

  const onGalleryHandle = () => {
    setState({ switchPosition: "left", animation: "center-to-left" });
  };

  const TripleSwitcherNarrowHandler = () => {
    if (isMap.data === false) {
      onMapHandle();
    } else {
      onGalleryHandle();
    }
  };

  const [currentZoomMap, setCurrentZoomMap] = useState(null);
  const [isOurListingsClicked, setIsOurListingsClicked] = useState(false);
  const [isAllOurListingsClicked, setIsAllOurListingsClicked] = useState(false);
  // const [isAllOurListings, setIsAllOurListings] = useState(null);

  const ourListingsBaseHandle = () => {
    setIsAllOurListings(!isAllOurListings);
  };

  useEffect(() => {
    if (isAllOurListings) {
      ourListingsHandle();
    }
    if (isAllOurListings === false) {
      ourAllListingsHandle();
    }
  }, [isAllOurListings]);

  const ourListingsHandle = () => {
    setIsOurListingsClicked(true);

    setIsOurListings(true);
    setCurrentZoomMap(mapZoom);
    setMapZoom(6);
    // setZoomToUrl(6);
    setFilters({
      ...filters,
      showOnly: {
        ...filters.showOnly,
        ourListings: true,
      },
      map: {
        ...filters.map,
        zoom: 6,
      },
    });
  };

  const ourAllListingsHandle = () => {
    setIsAllOurListingsClicked(true);

    if (currentZoomMap) {
      setMapZoom(currentZoomMap);
    }
    setIsOurListings(false);
    setFilters({
      ...filters,
      showOnly: {
        ...filters.showOnly,
        ourListings: false,
      },
      map: {
        ...filters.map,
        zoom: currentZoomMap ? currentZoomMap : null,
      },
    });
  };

  useEffect(() => {
    if (
      isOurListingsClicked &&
      isOurListings &&
      filters?.showOnly?.ourListings
    ) {
      applyHandler();
      setIsOurListingsClicked(false);
    }
  }, [isOurListingsClicked, filters?.showOnly?.ourListings, isOurListings]);

  useEffect(() => {
    if (
      isAllOurListingsClicked &&
      !filters?.showOnly?.ourListings &&
      filters?.map?.zoom === currentZoomMap
    ) {
      applyHandler();
      setIsAllOurListingsClicked(false);
    }
  }, [
    isAllOurListingsClicked,
    filters?.showOnly?.ourListings,
    filters?.map?.zoom,
  ]);

  const filterLayersBlockWrapper = (
    <FilterLayersBlockWrapper
      filters={filters}
      city={city}
      setCity={setCity}
      foundCity={foundCity}
      setFoundCity={setFoundCity}
      country={country}
      setCountry={setCountry}
      twCities={twCities}
      setTwCitiesPage={setTwCitiesPage}
      twCitiesPage={twCitiesPage}
      applyHandler={applyHandler}
      filterLocationCounties={filterLocationCounties}
      foundCountry={foundCountry}
      setFoundCountry={setFoundCountry}
      metro={metro}
      twPostals={twPostals}
      setTwPostals={setTwPostals}
      postal={postal}
      setPostal={setPostal}
      foundPostal={foundPostal}
      setFoundPostal={setFoundPostal}
      setTwPostalsPage={setTwPostalsPage}
      allPropertyTypes={allPropertyTypes}
      setAllPropertyTypes={setAllPropertyTypes}
      setFilters={setFilters}
      residentialSubType={residentialSubType}
      setResidentialSubType={setResidentialSubType}
      foundResidentialSubType={foundResidentialSubType}
      setFoundResidentialSubType={setFoundResidentialSubType}
      bathMax={bathMax}
      setBathMax={setBathMax}
      bathMin={bathMin}
      setBathMin={setBathMin}
      levelsMin={levelsMin}
      setLevelsMin={setLevelsMin}
      levelsMax={levelsMax}
      setLevelsMax={setLevelsMax}
      bedsMin={bedsMin}
      setBedsMin={setBedsMin}
      bedsMax={bedsMax}
      setBedsMax={setBedsMax}
      sqftMin={sqftMin}
      setSqftMin={setSqftMin}
      sqftMax={sqftMax}
      setSqftMax={setSqftMax}
      garagesSpacesMin={garagesSpacesMin}
      setGaragesSpacesMin={setGaragesSpacesMin}
      garagesSpacesMax={garagesSpacesMax}
      setGaragesSpacesMax={setGaragesSpacesMax}
      openNarrowFiltersHandle={openNarrowFiltersHandle}
      priceInfoLoading={priceInfoLoading}
      setPriceInfoLoading={setPriceInfoLoading}
    />
  );

  return (
    <div
      className="MainHeaderLayer-MainHeader-wrapper"
      style={{ width: isMap.data === true ? "100vw" : "100%" }}
    >
      <div className="MainHeaderLayer-MainHeader-content-wrapper">
        <div className="MainHeaderLayer-MainHeader-content">
          {filterLayersBlockWrapper}
          <div className="MainHeaderLayer-MainHeader-content-filters">
            <div id="sorting-block-icon-2_5" onClick={ourListingsBaseHandle}>
              <div className="sorting-block-icon-2_5-tooltip-all">
                <TooltipMessage content={"All Listings"} bottom />
              </div>
              <div className="sorting-block-icon-2_5-our-listings">
                <div className="sorting-block-icon-2_5-our-listings-realtytexas-icon"></div>
              </div>
            </div>
            {/* <div id="sorting-block-icon-3" onClick={openNarrowFiltersHandle}>
              <div className="sorting-block-icon-3-filters">Filters</div>
            </div> */}
          </div>
          <div className="MainHeaderLayer-MainHeader-content-TripleSwitcherSliderButton">
            <TripleSwitcherSliderButton
              getSwitchAnimation={getSwitchAnimation}
              state={state}
              setState={setState}
            />
          </div>
          <div
            className="MainHeaderLayer-MainHeader-content-TripleSwitcherSliderButton-mapOnly"
            onClick={TripleSwitcherNarrowHandler}
          >
            <div className="MainHeaderLayer-MainHeader-content-TripleSwitcherSliderButton-mapOnly-title">
              {isMap.data === false ? "Map" : "Gallery"}
            </div>
            <div className="MainHeaderLayer-MainHeader-content-TripleSwitcherSliderButton-mapOnly-icon">
              {isMap.data === false ? (
                <MapIcon color={colors.mainBg} width={20} height={20} />
              ) : (
                <Gallery4Icon color={colors.mainBg} width={20} height={20} />
              )}
            </div>
          </div>
        </div>
        {data?.total > 0 && (
          <div className="MainHeaderLayer-MainHeader-bottom">
            <ListingsInfo
              currentPage={currentPage}
              visibleHouseCards={visibleHouseCards}
              dataInRectPage={dataInRectPage}
            />
            <div className="MainHeaderLayer-MainHeader-content-SortingInputBlock-wrapper">
              <SortingInputBlock
                filters={filters}
                sortingValue={sortingValue}
                setSortingValue={setSortingValue}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                dataInRectPage={dataInRectPage}
                setDataInRectPage={setDataInRectPage}
                isDraw={isDraw}
                isMultyDraw={isMultyDraw}
                dataInPolyline={dataInPolyline}
                setDataInPolyline={setDataInPolyline}
                dataInMultyPolygones={dataInMultyPolygones}
                setDataInMultyPolygones={setDataInMultyPolygones}
                drawCoordinates={drawCoordinates}
                setDrawCoordinates={setDrawCoordinates}
              />
            </div>
          </div>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
};

export default MainHeaderLayer;

const ListingsInfo = ({ currentPage, visibleHouseCards, dataInRectPage }) => {
  const { data } = useSelector((state) => state.houseCards);
  const isMap = useSelector((state) => state.isMap);
  const houseCardDataInRect = useSelector((state) => state.houseCardsMap);
  return (
    <div className="MainHeaderLayer-ListingsInfo-wrapper">
      {data?.total && isMap.data === false && (
        <span>
          Page {currentPage + 1} of{" "}
          {Math.ceil(data?.total / data?.perpage).toLocaleString("en-US")}
        </span>
      )}
      {isMap.data === null && houseCardDataInRect?.data?.listings?.length > 0 && (
        <span>
          Page {dataInRectPage?.value} of{" "}
          {Math.ceil(
            houseCardDataInRect?.data.total / houseCardDataInRect?.data?.perpage
          ).toLocaleString("en-US")}
        </span>
      )}
      {data?.total && isMap.data !== true && (
        <span>
          {" "}
          (Showing{" "}
          {isMap.data === null &&
          houseCardDataInRect?.data?.listings?.length > 0
            ? houseCardDataInRect?.data?.listings?.length
            : visibleHouseCards?.length}{" "}
          of{" "}
          {isMap.data === null &&
          houseCardDataInRect?.data?.listings?.length > 0
            ? houseCardDataInRect?.data?.total?.toLocaleString("en-US")
            : data?.total.toLocaleString("en-US")}{" "}
          Listings)
        </span>
      )}

      {/* {isMap.data !== true && data?.total === 0 && (
          <span className="new-filters-page-house-cards-block-wrapper-displaying-listings-span">
            Showing no page
          </span>
        )} */}
    </div>
  );
};

const FilterBlocksWrapper = ({
  city,
  setCity,
  foundCity,
  setFoundCity,
  country,
  twCities,
  setTwCitiesPage,
  twCitiesPage,
  applyHandler,
}) => {
  return (
    <div className="MainHeaderLayer-FilterBlocksWrapper-wrapper">
      <CityInputBlock
        city={city}
        setCity={setCity}
        foundCity={foundCity}
        setFoundCity={setFoundCity}
        country={country}
        twCities={twCities}
        setTwCitiesPage={setTwCitiesPage}
        twCitiesPage={twCitiesPage}
        applyHandler={applyHandler}
      />
    </div>
  );
};

const FilterLayersBlockWrapper = ({
  filters,
  city,
  setCity,
  foundCity,
  setFoundCity,
  country,
  setCountry,
  twCities,
  setTwCitiesPage,
  twCitiesPage,
  applyHandler,
  filterLocationCounties,
  foundCountry,
  setFoundCountry,
  metro,
  twPostals,
  setTwPostals,
  postal,
  setPostal,
  foundPostal,
  setFoundPostal,
  setTwPostalsPage,
  allPropertyTypes,
  setAllPropertyTypes,
  setFilters,
  residentialSubType,
  setResidentialSubType,
  foundResidentialSubType,
  setFoundResidentialSubType,
  bathMax,
  setBathMax,
  bathMin,
  setBathMin,
  levelsMin,
  setLevelsMin,
  levelsMax,
  setLevelsMax,
  bedsMin,
  setBedsMin,
  bedsMax,
  setBedsMax,
  sqftMin,
  setSqftMin,
  sqftMax,
  setSqftMax,
  garagesSpacesMin,
  setGaragesSpacesMin,
  garagesSpacesMax,
  setGaragesSpacesMax,
  openNarrowFiltersHandle,
  priceInfoLoading,
  setPriceInfoLoading,
}) => {
  const isMap = useSelector((state) => state.isMap);
  const { isOpenNarrowFilters } = useSelector((state) => state.isResetFilters);
  const [isPopUpShowType, setIsPopUpShowType] = useState(false);
  const [isPopUpShowLoc, setIsPopUpShowLoc] = useState(false);
  const [isPopUpShowSpecs, setIsPopUpShowSpecs] = useState(false);
  const [isPopUpShowPrice, setIsPopUpShowPrice] = useState(null);

  const [isAnyPopUpShow, setIsAnyPopUpShow] = useState(false);

  useEffect(() => {
    if (
      isPopUpShowType ||
      isPopUpShowLoc ||
      isPopUpShowSpecs ||
      isOpenNarrowFilters
    ) {
      setIsAnyPopUpShow(true);
    }
    if (
      !isPopUpShowType &&
      !isPopUpShowLoc &&
      !isPopUpShowSpecs &&
      !isOpenNarrowFilters
    ) {
      setIsAnyPopUpShow(false);
    }
  }, [isPopUpShowType, isPopUpShowLoc, isPopUpShowSpecs, isOpenNarrowFilters]);

  useEffect(() => {
    if (isAnyPopUpShow) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [isAnyPopUpShow]);

  return (
    <React.Fragment>
      <div
        className={
          isMap.data === null
            ? "MainHeaderLayer-MainHeader-filters-wrapper MainHeaderLayer-MainHeader-filters-wrapper-both"
            : "MainHeaderLayer-MainHeader-filters-wrapper"
        }
      >
        <HeaderLayerType
          applyHandler={applyHandler}
          allPropertyTypes={allPropertyTypes}
          setAllPropertyTypes={setAllPropertyTypes}
          setFilters={setFilters}
          filters={filters}
          residentialSubType={residentialSubType}
          setResidentialSubType={setResidentialSubType}
          isPopUpShowType={isPopUpShowType}
          setIsPopUpShowLoc={setIsPopUpShowLoc}
          setIsPopUpShowType={setIsPopUpShowType}
          setIsPopUpShowSpecs={setIsPopUpShowSpecs}
          setIsPopUpShowPrice={setIsPopUpShowPrice}
          residentialSubType={residentialSubType}
          setResidentialSubType={setResidentialSubType}
          foundResidentialSubType={foundResidentialSubType}
          setFoundResidentialSubType={setFoundResidentialSubType}
        />
      </div>
      <div
        className={
          isMap.data === null
            ? "MainHeaderLayer-MainHeader-filters-wrapper MainHeaderLayer-MainHeader-filters-wrapper-both"
            : "MainHeaderLayer-MainHeader-filters-wrapper"
        }
      >
        <HeaderLayerLocation
          filterLocationCounties={filterLocationCounties}
          foundCountry={foundCountry}
          setFoundCountry={setFoundCountry}
          metro={metro}
          city={city}
          setCity={setCity}
          foundCity={foundCity}
          setFoundCity={setFoundCity}
          country={country}
          setCountry={setCountry}
          twCities={twCities}
          setTwCitiesPage={setTwCitiesPage}
          twCitiesPage={twCitiesPage}
          applyHandler={applyHandler}
          twPostals={twPostals}
          setTwPostals={setTwPostals}
          postal={postal}
          setPostal={setPostal}
          foundPostal={foundPostal}
          setFoundPostal={setFoundPostal}
          setTwPostalsPage={setTwPostalsPage}
          isPopUpShowLoc={isPopUpShowLoc}
          setIsPopUpShowLoc={setIsPopUpShowLoc}
          setIsPopUpShowType={setIsPopUpShowType}
          setIsPopUpShowSpecs={setIsPopUpShowSpecs}
          setIsPopUpShowPrice={setIsPopUpShowPrice}
        />
      </div>

      <div
        className={
          isMap.data === null
            ? "MainHeaderLayer-MainHeader-filters-wrapper MainHeaderLayer-MainHeader-filters-wrapper-both"
            : "MainHeaderLayer-MainHeader-filters-wrapper"
        }
      >
        <HeaderLayerPrice
          isPopUpShowPrice={isPopUpShowPrice}
          setIsPopUpShowPrice={setIsPopUpShowPrice}
          setIsPopUpShowSpecs={setIsPopUpShowSpecs}
          setIsPopUpShowType={setIsPopUpShowType}
          setIsPopUpShowLoc={setIsPopUpShowLoc}
          applyHandler={applyHandler}
          priceInfoLoading={priceInfoLoading}
          setPriceInfoLoading={setPriceInfoLoading}
          filters={filters}
        />
      </div>
      <div
        className={
          isMap.data === null
            ? "MainHeaderLayer-MainHeader-filters-wrapper MainHeaderLayer-MainHeader-filters-wrapper-both"
            : "MainHeaderLayer-MainHeader-filters-wrapper"
        }
      >
        <HeaderLayerSpecs
          isPopUpShowSpecs={isPopUpShowSpecs}
          setIsPopUpShowSpecs={setIsPopUpShowSpecs}
          setIsPopUpShowType={setIsPopUpShowType}
          setIsPopUpShowLoc={setIsPopUpShowLoc}
          setIsPopUpShowPrice={setIsPopUpShowPrice}
          applyHandler={applyHandler}
          bathMax={bathMax}
          setBathMax={setBathMax}
          bathMin={bathMin}
          setBathMin={setBathMin}
          levelsMin={levelsMin}
          setLevelsMin={setLevelsMin}
          levelsMax={levelsMax}
          setLevelsMax={setLevelsMax}
          bedsMin={bedsMin}
          setBedsMin={setBedsMin}
          bedsMax={bedsMax}
          setBedsMax={setBedsMax}
          sqftMin={sqftMin}
          setSqftMin={setSqftMin}
          sqftMax={sqftMax}
          setSqftMax={setSqftMax}
          garagesSpacesMin={garagesSpacesMin}
          setGaragesSpacesMin={setGaragesSpacesMin}
          garagesSpacesMax={garagesSpacesMax}
          setGaragesSpacesMax={setGaragesSpacesMax}
        />
      </div>
      <div
        className={
          isMap.data === null
            ? "MainHeaderLayer-MainHeader-filters-wrapper MainHeaderLayer-MainHeader-filters-wrapper-both"
            : "MainHeaderLayer-MainHeader-filters-wrapper"
        }
      >
        <div id="sorting-block-icon-3" onClick={openNarrowFiltersHandle}>
          <div className="sorting-block-icon-3-filters">
            <div className="sorting-block-icon-3-filters-title">
              All Filters
            </div>
            <div className="sorting-block-icon-3-filters-icons">
              <FiltersIcon width={18} height={18} color={colors.white} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const CityInputBlock = ({
  city,
  setCity,
  foundCity,
  setFoundCity,
  country,
  twCities,
  setTwCitiesPage,
  twCitiesPage,
  applyHandler,
}) => {
  const { data, isLoading } = useSelector((state) => state.filterLocationLists);
  const dispatch = useDispatch();
  const [cityInputOpened, setCityInputOpened] = useState(false);
  const [isSearchCity, setIsSearchCity] = useState(false);

  const observerList = (name, callback) => {
    const lastItem = document.querySelector(
      `.HeaderMultipleInput-active-input-list-wrapper-li-${name}:last-child`
    );
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          // setTwCitiesPage((twCitiesPage) => twCitiesPage + 1);
          callback((c) => c + 1);
          observer.disconnect();
        }
      },
      { threshold: 0 }
    );
    observer.observe(lastItem);
  };

  // useEffect(() => {
  //   if (!isSearchCity) {
  //     const lastItem = document.querySelector(
  //       ".HeaderMultipleInput-active-input-list-wrapper-li-cityList"
  //     );
  //     if (lastItem && twCities?.length > 0)
  //       observerList("cityList", setTwCitiesPage);
  //   }
  // }, [twCities.length, cityInputOpened, isSearchCity]);

  const filterLocationCities = (value) => {
    let countyCodes;
    if (country[0] && country[0] !== "") {
      countyCodes = country.map((c) => +c.CountyCode);
    } else countyCodes = [];
    dispatch(getFilterLocationCities(countyCodes, value));
  };

  return (
    <div className="HeaderMultipleInput-CityInputBlock-wrapper">
      <HeaderMultipleInput
        searchIcon
        searchCallback={applyHandler}
        colorEndIcon={"white"}
        name="c17y"
        list={
          (data?.cities && country && country[0] && country[0] !== "") ||
          isSearchCity
            ? data?.cities
            : // : filterLocationData?.cities || twCities
              twCities
        }
        // list2={filterLocationData?.cities || twCities}
        list2={twCities}
        placeholder={
          country && country?.length > 0 ? "Select" : "Type city to see results"
        }
        listTop="42px"
        item={city}
        setItem={setCity}
        found={
          city
            ? foundCity?.filter((obj1) => {
                if (city) {
                  return !city.some((obj2) => {
                    return obj1.subdomain === obj2.subdomain;
                  });
                } else {
                  return true;
                }
              })
            : foundCity
        }
        setFound={setFoundCity}
        field="City"
        height={30}
        parrentValue={country}
        parrentName="County"
        // parrentValue2={postal}
        // parrentName2="Postal"
        callback={
          country && country[0] && country[0] !== ""
            ? filterLocationCities
            : null
        }
        callback2={
          country && country[0] && country[0] !== ""
            ? null
            : filterLocationCities
        }
        isLoading={isLoading}
        classNameLi="cityList"
        setIsOpened={setCityInputOpened}
        isOpened={cityInputOpened}
        setIsSearch={setIsSearchCity}
      />
    </div>
  );
};
