export const isUserLogin = () => {
  const myRTPValue = localStorage?.getItem("MyRTP");
  if (myRTPValue) {
    const myRTP = JSON?.parse(myRTPValue);
    if (myRTP && myRTP.hasOwnProperty("SubjectID")) {
      return myRTP.SubjectID;
    }
  } else return false;
};

export const toObjFilterConverter = (obj) => {
  const arrayData = [];

  //quickSearch
  if (obj?.quickSearch && obj?.quickSearch[0] && obj?.quickSearch[0]?.cities) {
    arrayData.push({
      column: "City",
      operator: "eq",
      value: obj?.quickSearch[0]?.cities[0]?.City,
    });
  }

  if (obj?.quickSearch && obj?.quickSearch[0] && obj?.quickSearch[0]?.postal) {
    arrayData.push({
      column: "PostalCode",
      operator: "in",
      value: [obj?.quickSearch[0]?.postal[0]?.value],
    });
  }

  //propertyType propertySubType

  if (obj?.propertyType && obj?.propertyType[0]) {
    arrayData.push({
      column: "PropertyType",
      operator: "eq",
      value: obj?.propertyType[0]?.PropertyType,
    });
  }

  if (obj.propertySubType && obj.propertySubType[0]) {
    const propSubTypes = obj.propertySubType.map((sub) => sub.PropertySubType);
    arrayData.push({
      column: "PropertySubType",
      operator: "in",
      value: [...propSubTypes],
    });
  }

  //Filter location
  if (
    obj?.filterLocation?.region?.value &&
    !obj?.filterLocation?.metro?.subdomain &&
    !obj?.filterLocation?.country?.length > 0 &&
    !obj?.filterLocation?.city?.length > 0 &&
    !obj?.filterLocation?.postal?.length > 0
  ) {
    // const temp = obj?.filterLocation?.metro.map((c) => c.value);
    arrayData.push({
      column: "Region",
      operator: "eq",
      value: obj?.filterLocation?.region.subdomain,
    });
  }

  if (
    obj?.filterLocation?.metro?.subdomain &&
    !obj?.filterLocation?.country?.length > 0 &&
    !obj?.filterLocation?.city?.length > 0 &&
    !obj?.filterLocation?.postal?.length > 0
  ) {
    // const temp = obj?.filterLocation?.metro.map((c) => c.value);
    arrayData.push({
      column: "Metro",
      operator: "eq",
      value: obj?.filterLocation?.metro.subdomain,
    });
  }

  if (
    obj?.filterLocation?.country &&
    !obj?.filterLocation?.city?.length > 0 &&
    !obj?.filterLocation?.postal?.length > 0
  ) {
    const temp = obj?.filterLocation?.country.map((c) => c.value);
    arrayData.push({
      column: "CountyOrParish",
      operator: "in",
      value: temp,
    });
  }

  if (obj?.filterLocation?.city && !obj?.filterLocation?.postal?.length > 0) {
    const temp = obj?.filterLocation?.city.map((c) => c.value);
    arrayData.push({
      column: "City",
      operator: "in",
      value: temp,
    });
  }

  if (
    obj?.filterLocation?.postal &&
    obj?.filterLocation?.postal[0] !== "" &&
    obj?.filterLocation?.postal?.length > 0
  ) {
    const temp = obj?.filterLocation?.postal.map((c) => c.value);
    arrayData.push({
      column: "PostalCode",
      operator: "in",
      value: temp,
    });
  }

  //Home Features
  if (obj?.homeFeatures?.setMinMaxPrice?.min?.label) {
    arrayData.push({
      column: "ListPrice",
      operator: "min",
      value: obj.homeFeatures?.minMaxPriceValues.min,
      // value: obj.homeFeatures?.setMinMaxPrice.min.value,
    });
  }
  if (obj?.homeFeatures?.setMinMaxPrice?.max?.label) {
    arrayData.push({
      column: "ListPrice",
      operator: "max",
      value: obj.homeFeatures?.minMaxPriceValues.max,
      // value: obj.homeFeatures?.setMinMaxPrice.max.value,
    });
  }

  if (obj?.homeFeatures?.bath?.max) {
    arrayData.push({
      column: "BathroomsFull",
      operator: "max",
      value: obj.homeFeatures?.bath?.max,
    });
  }
  if (obj?.homeFeatures?.bath?.min) {
    arrayData.push({
      column: "BathroomsFull",
      operator: "min",
      value: obj.homeFeatures?.bath?.min,
    });
  }

  if (obj?.homeFeatures?.beds?.min) {
    arrayData.push({
      column: "BedroomsTotal",
      operator: "min",
      value: obj.homeFeatures?.beds?.min,
    });
  }
  if (obj?.homeFeatures?.beds?.max) {
    arrayData.push({
      column: "BedroomsTotal",
      operator: "max",
      value: obj.homeFeatures?.beds?.max,
    });
  }

  if (obj?.homeFeatures?.lotSizeAcres?.max?.value) {
    arrayData.push({
      column: "LotSizeAcres",
      operator: "max",
      value: obj.homeFeatures?.lotSizeAcres?.max?.value,
    });
  }
  if (obj?.homeFeatures?.lotSizeAcres?.min?.value) {
    arrayData.push({
      column: "LotSizeAcres",
      operator: "min",
      value: obj.homeFeatures?.lotSizeAcres?.min?.value,
    });
  }

  if (obj?.homeFeatures?.garagesSpaces?.min) {
    arrayData.push({
      column: "GarageSpaces",
      operator: "min",
      value: obj.homeFeatures?.garagesSpaces?.min,
    });
  }
  if (obj?.homeFeatures?.garagesSpaces?.max) {
    arrayData.push({
      column: "GarageSpaces",
      operator: "max",
      value: obj.homeFeatures?.garagesSpaces?.max,
    });
  }

  if (obj?.homeFeatures?.yearBuilt?.max) {
    arrayData.push({
      column: "YearBuilt",
      operator: "max",
      value: obj.homeFeatures?.yearBuilt?.max,
    });
  }
  if (obj?.homeFeatures?.yearBuilt?.min) {
    arrayData.push({
      column: "YearBuilt",
      operator: "min",
      value: obj.homeFeatures?.yearBuilt?.min,
    });
  }

  if (obj?.homeFeatures?.levels?.min) {
    arrayData.push({
      column: "Stories",
      operator: "min",
      value: +obj.homeFeatures?.levels?.min.toString(),
    });
  }

  if (obj?.homeFeatures?.levels?.max) {
    arrayData.push({
      column: "Stories",
      operator: "max",
      value: +obj.homeFeatures?.levels?.max.toString(),
    });
  }

  if (obj?.homeFeatures?.sqft?.max) {
    arrayData.push({
      column: "AboveGradeFinishedArea",
      operator: "max",
      value: obj.homeFeatures?.sqft?.max,
    });
  }

  if (obj?.homeFeatures?.sqft?.min) {
    arrayData.push({
      column: "AboveGradeFinishedArea",
      operator: "min",
      value: obj.homeFeatures?.sqft?.min,
    });
  }

  if (obj?.filterLocation?.city?.City) {
    arrayData.push({
      column: "City",
      operator: "eq",
      value: obj?.filterLocation?.city?.City,
    });
  }

  if (obj?.homeFeatures?.primaryBedOnMain === true) {
    arrayData.push({
      column: "MainLevelPrimary",
      operator: "eq",
      value: obj?.homeFeatures?.primaryBedOnMain === true ? "true" : "false",
    });
  }

  if (obj?.homeFeatures?.fireplace === true) {
    arrayData.push({
      column: "FireplaceYN",
      operator: "eq",
      value: obj?.homeFeatures?.fireplace === true ? "true" : "false",
    });
  }

  if (obj?.homeFeatures?.accessibilityFeatures === true) {
    arrayData.push({
      column: "AccessibilityFeatures",
      operator: "eq",
      value:
        obj?.homeFeatures?.accessibilityFeatures === true ? "true" : "false",
    });
  }

  if (obj?.homeFeatures?.poolFeatures === true) {
    arrayData.push({
      column: "PoolPrivateYN",
      operator: "eq",
      value: obj?.homeFeatures?.poolFeatures === true ? "true" : "false",
    });
  }
  //Status only
  const active = obj?.status?.active;
  const activeUnderContract = obj?.status?.activeUnderContract;
  const closed = obj?.status?.closed;
  const commingSoon = obj?.status?.commingSoon;
  const pending = obj?.status?.pending;
  if (active || activeUnderContract || closed || commingSoon || pending) {
    const arrValue = [];
    if (active === true) arrValue.push("Active");
    if (activeUnderContract === true) arrValue.push("ActiveUnderContract");
    if (closed === true) arrValue.push("Closed");
    if (commingSoon === true) arrValue.push("CommingSoon");
    if (pending === true) arrValue.push("Pending");
    if (arrValue.length > 0 && closed !== true)
      arrayData.push({
        column: "MlsStatus",
        operator: "in",
        value: arrValue,
      });
    if (arrValue.length > 0 && closed === true) {
      arrayData.push({
        column: "CloseDate",
        operator: "ge",
        // value: "2024-02-15" + "T00:00:00Z", //CLOSED DATE STATUS TO
        value: obj?.status?.closedDateFrom + "T00:00:00Z",
      });
      arrayData.push({
        column: "CloseDate",
        operator: "le",
        // value: "2024-05-15" + "T00:00:00Z", //CLOSED DATE STATUS FROM
        value: obj?.status?.closedDateTo + "T00:00:00Z",
      });
    }
  }
  //TimeFrame only
  const oneDay = obj?.timeFrame?.oneDay;
  const sevenDay = obj?.timeFrame?.sevenDay;
  const thirtyDay = obj?.timeFrame?.thirtyDay;
  if (oneDay || sevenDay || thirtyDay) {
    let value = null;
    if (oneDay) value = 0;
    if (sevenDay) value = 7;
    if (thirtyDay) value = 30;
    arrayData.push({
      column: "DOM",
      operator: "le",
      value: value,
    });
  }
  //Show only
  if (obj?.showOnly?.openHouses === true) {
    arrayData.push({
      column: "oh",
      operator: "eq",
      value: obj?.showOnly?.openHouses === true ? "true" : "false",
    });
  }

  if (obj?.showOnly?.ourListings === true) {
    arrayData.push({
      column: "rtlist",
      operator: "eq",
      value: obj?.showOnly?.ourListings === true ? "true" : "false",
    });
  }

  if (arrayData.length === 0) return null;
  return arrayData;
};

export const toObjMLSFilterConverter = (obj) => {
  const arrayData = [];
  //propertyType propertySubType

  if (obj?.propertyType?.residential) {
    const temp = obj?.propertySubType?.residentialSubType?.map((c) => c.value);
    arrayData.push({
      column: "PropertySubType",
      operator: "in",
      value: temp,
    });
    arrayData.push({
      column: "PropertyType",
      operator: "eq",
      value: "Residential",
    });
  }

  if (obj?.propertyType?.commercialLease) {
    const temp = obj?.propertySubType?.residentialSubType?.map((c) => c.value);
    arrayData.push({
      column: "PropertySubType",
      operator: "in",
      value: temp,
    });
    arrayData.push({
      column: "PropertyType",
      operator: "eq",
      value: "CommercialLease",
    });
  }

  if (obj?.propertyType?.commercialSale) {
    const temp = obj?.propertySubType?.residentialSubType?.map((c) => c.value);
    arrayData.push({
      column: "PropertySubType",
      operator: "in",
      value: temp,
    });
    arrayData.push({
      column: "PropertyType",
      operator: "eq",
      value: "CommercialSale",
    });
  }

  if (obj?.propertyType?.farmAndRanch) {
    const temp = obj?.propertySubType?.residentialSubType?.map((c) => c.value);
    arrayData.push({
      column: "PropertySubType",
      operator: "in",
      value: temp,
    });
    arrayData.push({
      column: "PropertyType",
      operator: "eq",
      value: "Farm",
    });
  }

  if (obj?.propertyType?.land) {
    const temp = obj?.propertySubType?.residentialSubType?.map((c) => c.value);
    arrayData.push({
      column: "PropertySubType",
      operator: "in",
      value: temp,
    });
    arrayData.push({
      column: "PropertyType",
      operator: "eq",
      value: "Land",
    });
  }

  if (obj?.propertyType?.ResidentialIncome) {
    const temp = obj?.propertySubType?.residentialSubType?.map((c) => c.value);
    arrayData.push({
      column: "PropertySubType",
      operator: "in",
      value: temp,
    });
    arrayData.push({
      column: "PropertyType",
      operator: "eq",
      value: "ResidentialIncome",
    });
  }

  if (obj?.propertyType?.residentialLease) {
    const temp = obj?.propertySubType?.residentialSubType?.map((c) => c.value);
    arrayData.push({
      column: "PropertySubType",
      operator: "in",
      value: temp,
    });
    arrayData.push({
      column: "PropertyType",
      operator: "eq",
      value: "ResidentialLease",
    });
  }
  //   obj?.setMinMaxPrice?.min?.label ||
  //Home Features
  if (obj?.homeFeatures?.setMinMaxPrice?.min?.label) {
    arrayData.push({
      column: "ListPrice",
      operator: "min",
      value: obj.homeFeatures?.minMaxPriceValues.min,
      // value: obj.homeFeatures?.setMinMaxPrice.min.value,
    });
  }
  if (obj?.homeFeatures?.setMinMaxPrice?.max?.label) {
    arrayData.push({
      column: "ListPrice",
      operator: "max",
      value: obj.homeFeatures?.minMaxPriceValues.max,
      // value: obj.homeFeatures?.setMinMaxPrice.max.value,
    });
  }

  if (obj?.homeFeatures?.bath?.max) {
    arrayData.push({
      column: "BathroomsFull",
      operator: "max",
      value: obj.homeFeatures?.bath?.max,
    });
  }
  if (obj?.homeFeatures?.bath?.min) {
    arrayData.push({
      column: "BathroomsFull",
      operator: "min",
      value: obj.homeFeatures?.bath?.min,
    });
  }

  if (obj?.homeFeatures?.beds?.min) {
    arrayData.push({
      column: "BedroomsTotal",
      operator: "min",
      value: obj.homeFeatures?.beds?.min,
    });
  }
  if (obj?.homeFeatures?.beds?.max) {
    arrayData.push({
      column: "BedroomsTotal",
      operator: "max",
      value: obj.homeFeatures?.beds?.max,
    });
  }

  if (obj?.homeFeatures?.lotSizeAcres?.max?.value) {
    arrayData.push({
      column: "LotSizeAcres",
      operator: "max",
      value: obj.homeFeatures?.lotSizeAcres?.max?.value,
    });
  }
  if (obj?.homeFeatures?.lotSizeAcres?.min?.value) {
    arrayData.push({
      column: "LotSizeAcres",
      operator: "min",
      value: obj.homeFeatures?.lotSizeAcres?.min?.value,
    });
  }

  if (obj?.homeFeatures?.garagesSpaces?.min) {
    arrayData.push({
      column: "GarageSpaces",
      operator: "min",
      value: obj.homeFeatures?.garagesSpaces?.min,
    });
  }
  if (obj?.homeFeatures?.garagesSpaces?.max) {
    arrayData.push({
      column: "GarageSpaces",
      operator: "max",
      value: obj.homeFeatures?.garagesSpaces?.max,
    });
  }

  if (obj?.homeFeatures?.yearBuilt?.max) {
    arrayData.push({
      column: "YearBuilt",
      operator: "max",
      value: obj.homeFeatures?.yearBuilt?.max,
    });
  }
  if (obj?.homeFeatures?.yearBuilt?.min) {
    arrayData.push({
      column: "YearBuilt",
      operator: "min",
      value: obj.homeFeatures?.yearBuilt?.min,
    });
  }

  if (obj?.homeFeatures?.levels?.min) {
    arrayData.push({
      column: "Stories",
      operator: "min",
      value: +obj.homeFeatures?.levels?.min.toString(),
    });
  }

  if (obj?.homeFeatures?.levels?.max) {
    arrayData.push({
      column: "Stories",
      operator: "max",
      value: +obj.homeFeatures?.levels?.max.toString(),
    });
  }

  if (obj?.homeFeatures?.sqft?.max) {
    arrayData.push({
      column: "AboveGradeFinishedArea",
      operator: "max",
      value: obj.homeFeatures?.sqft?.max,
    });
  }

  if (obj?.homeFeatures?.sqft?.min) {
    arrayData.push({
      column: "AboveGradeFinishedArea",
      operator: "min",
      value: obj.homeFeatures?.sqft?.min,
    });
  }

  if (obj?.filterLocation?.city?.City) {
    arrayData.push({
      column: "City",
      operator: "eq",
      value: obj?.filterLocation?.city?.City,
    });
  }

  if (obj?.homeFeatures?.primaryBedOnMain === true) {
    arrayData.push({
      column: "MainLevelPrimary",
      operator: "eq",
      value: obj?.homeFeatures?.primaryBedOnMain === true ? "true" : "false",
    });
  }

  if (obj?.homeFeatures?.fireplace === true) {
    arrayData.push({
      column: "FireplaceYN",
      operator: "eq",
      value: obj?.homeFeatures?.fireplace === true ? "true" : "false",
    });
  }

  if (obj?.homeFeatures?.accessibilityFeatures === true) {
    arrayData.push({
      column: "AccessibilityFeatures",
      operator: "eq",
      value:
        obj?.homeFeatures?.accessibilityFeatures === true ? "true" : "false",
    });
  }

  if (obj?.homeFeatures?.poolFeatures === true) {
    arrayData.push({
      column: "PoolPrivateYN",
      operator: "eq",
      value: obj?.homeFeatures?.poolFeatures === true ? "true" : "false",
    });
  }
  //Status only
  const active = obj?.status?.active;
  const activeUnderContract = obj?.status?.activeUnderContract;
  const closed = obj?.status?.closed;
  const commingSoon = obj?.status?.commingSoon;
  const pending = obj?.status?.pending;
  if (active || activeUnderContract || closed || commingSoon || pending) {
    const arrValue = [];
    if (active === true) arrValue.push("Active");
    if (activeUnderContract === true) arrValue.push("ActiveUnderContract");
    if (closed === true) arrValue.push("Closed");
    if (commingSoon === true) arrValue.push("CommingSoon");
    if (pending === true) arrValue.push("Pending");
    if (arrValue.length > 0 && closed !== true)
      arrayData.push({
        column: "MlsStatus",
        operator: "in",
        value: arrValue,
      });
    if (arrValue.length > 0 && closed === true) {
      arrayData.push({
        column: "CloseDate",
        operator: "ge",
        // value: "2024-02-15" + "T00:00:00Z", //CLOSED DATE STATUS TO
        value: obj?.status?.closedDateFrom + "T00:00:00Z",
      });
      arrayData.push({
        column: "CloseDate",
        operator: "le",
        // value: "2024-05-15" + "T00:00:00Z", //CLOSED DATE STATUS FROM
        value: obj?.status?.closedDateTo + "T00:00:00Z",
      });
    }
  }
  //TimeFrame only
  const oneDay = obj?.timeFrame?.oneDay;
  const sevenDay = obj?.timeFrame?.sevenDay;
  const thirtyDay = obj?.timeFrame?.thirtyDay;
  if (oneDay || sevenDay || thirtyDay) {
    let value = null;
    if (oneDay) value = 0;
    if (sevenDay) value = 7;
    if (thirtyDay) value = 30;
    arrayData.push({
      column: "DOM",
      operator: "le",
      value: value,
    });
  }
  //Show only
  if (obj?.showOnly?.openHouses === true) {
    arrayData.push({
      column: "oh",
      operator: "eq",
      value: obj?.showOnly?.openHouses === true ? "true" : "false",
    });
  }

  if (obj?.showOnly?.ourListings === true) {
    arrayData.push({
      column: "rtlist",
      operator: "eq",
      value: obj?.showOnly?.ourListings === true ? "true" : "false",
    });
  }

  if (arrayData.length === 0) return null;
  return arrayData;
};

export const toQParamsObjFilterConverter = (obj, subdomainData) => {
  const arrayData = [];

  // arrayData.push({
  //     column: "filtersParams",
  //     value: "true",
  //   });

  if (
    obj?.propertyType[0]?.PropertyType &&
    obj?.propertyType[0]?.PropertyType !== "Residential"
  ) {
    arrayData.push({
      column: "PropertyType",
      value: obj?.propertyType[0].PropertyType,
    });
  }

  if (obj?.propertySubType?.length > 0) {
    let list = obj?.propertySubType.map((sub) => {
      return sub.PropertySubType;
    });
    if (
      obj?.propertyType[0]?.PropertyType &&
      obj?.propertyType[0]?.PropertyType === "Residential"
    ) {
      arrayData.push({
        column: "PropertyType",
        value: obj?.propertyType[0].PropertyType,
      });
    }

    arrayData.push({
      column: "PropertySubType",
      value: list.join(","),
    });
  }
  //Filter location
  if (obj?.filterLocation?.region?.subdomain) {
    arrayData.push({
      column: "Region",
      value: obj?.filterLocation?.region.subdomain,
    });
  }

  if (obj?.filterLocation?.metro?.subdomain) {
    arrayData.push({
      column: "Metro",
      value: obj?.filterLocation?.metro.subdomain,
    });
  }

  if (obj?.filterLocation?.country?.length > 0) {
    const temp = obj?.filterLocation?.country.map((c) => c.subdomain);
    arrayData.push({
      column: "County",
      value: temp.join(","),
    });
  }

  if (obj?.filterLocation?.city?.length > 0) {
    const temp = obj?.filterLocation?.city.map((c) => c.subdomain);
    arrayData.push({
      column: "City",
      value: temp.join(","),
    });
  }

  if (
    obj?.filterLocation?.postal &&
    obj?.filterLocation?.postal[0] !== "" &&
    obj?.filterLocation?.postal?.length > 0
  ) {
    const temp = obj?.filterLocation?.postal.map((c) => c.value);
    arrayData.push({
      column: "PostalCode",
      value: temp.join(","),
    });
  }

  //Home Features
  if (
    obj?.homeFeatures?.minMaxPriceValues?.min !== null &&
    obj?.homeFeatures?.setMinMaxPrice?.min !== null
  ) {
    arrayData.push({
      column: "ListPriceMin",
      value: [obj.homeFeatures?.minMaxPriceValues.min.toString()].join(","),
    });
  }
  if (
    obj?.homeFeatures?.minMaxPriceValues?.max !== null &&
    obj?.homeFeatures?.setMinMaxPrice?.max !== null
  ) {
    arrayData.push({
      column: "ListPriceMax",
      value: [obj.homeFeatures?.minMaxPriceValues?.max.toString()].join(","),
    });
  }

  if (obj?.homeFeatures?.bath?.max) {
    arrayData.push({
      column: "BathroomsFullMax",
      value: [obj.homeFeatures?.bath?.max.toString()].join(","),
    });
  }
  if (obj?.homeFeatures?.bath?.min) {
    arrayData.push({
      column: "BathroomsFullMin",
      value: [obj.homeFeatures?.bath?.min.toString()].join(","),
    });
  }

  if (obj?.homeFeatures?.beds?.min) {
    arrayData.push({
      column: "BedroomsTotalMin",
      value: [obj.homeFeatures?.beds?.min.toString()].join(","),
    });
  }
  if (obj?.homeFeatures?.beds?.max) {
    arrayData.push({
      column: "BedroomsTotalMax",
      value: [obj.homeFeatures?.beds?.max.toString()].join(","),
    });
  }

  if (obj?.homeFeatures?.lotSizeAcres?.max?.value) {
    arrayData.push({
      column: "LotSizeAcresMax",
      value: [obj.homeFeatures?.lotSizeAcres?.max?.value.toString()].join(","),
    });
  }
  if (obj?.homeFeatures?.lotSizeAcres?.min?.value) {
    arrayData.push({
      column: "LotSizeAcresMin",
      value: [obj.homeFeatures?.lotSizeAcres?.min?.value.toString()].join(","),
    });
  }

  if (obj?.homeFeatures?.garagesSpaces?.min) {
    arrayData.push({
      column: "GarageSpacesMin",
      value: [obj.homeFeatures?.garagesSpaces?.min.toString()].join(","),
    });
  }
  if (obj?.homeFeatures?.garagesSpaces?.max) {
    arrayData.push({
      column: "GarageSpacesMax",
      value: [obj.homeFeatures?.garagesSpaces?.max.toString()].join(","),
    });
  }

  if (obj?.homeFeatures?.yearBuilt?.max) {
    arrayData.push({
      column: "YearBuiltMax",
      value: [obj.homeFeatures?.yearBuilt?.max.toString()].join(","),
    });
  }
  if (obj?.homeFeatures?.yearBuilt?.min) {
    arrayData.push({
      column: "YearBuiltMin",
      value: [obj.homeFeatures?.yearBuilt?.min.toString()].join(","),
    });
  }

  if (obj?.homeFeatures?.levels?.min) {
    arrayData.push({
      column: "LevelsMin",
      value: [obj.homeFeatures?.levels?.min.toString()].join(","),
    });
  }

  if (obj?.homeFeatures?.levels?.max) {
    arrayData.push({
      column: "LevelsMax",
      value: [obj.homeFeatures?.levels?.max.toString()].join(","),
    });
  }

  if (obj?.homeFeatures?.sqft?.max) {
    arrayData.push({
      column: "AboveGradeFinishedAreaMax",
      value: [obj.homeFeatures?.sqft?.max.toString()].join(","),
    });
  }

  if (obj?.homeFeatures?.sqft?.min) {
    arrayData.push({
      column: "AboveGradeFinishedAreaMin",
      value: [obj.homeFeatures?.sqft?.min.toString()].join(","),
    });
  }

  if (obj?.homeFeatures?.primaryBedOnMain === true) {
    arrayData.push({
      column: "MainLevelPrimary",
      value:
        obj?.homeFeatures?.primaryBedOnMain === true
          ? ["true"].join(",")
          : null,
    });
  }

  if (obj?.homeFeatures?.fireplace === true) {
    arrayData.push({
      column: "Fireplace",
      value: obj?.homeFeatures?.fireplace === true ? ["true"].join(",") : null,
    });
  }

  if (obj?.homeFeatures?.accessibilityFeatures === true) {
    arrayData.push({
      column: "PoolPrivateYN",
      value:
        obj?.homeFeatures?.accessibilityFeatures === true
          ? ["true"].join(",")
          : null,
    });
  }

  if (obj?.homeFeatures?.poolFeatures === true) {
    arrayData.push({
      column: "PoolPrivateYN",
      operator: "eq",
      value:
        obj?.homeFeatures?.poolFeatures === true ? ["true"].join(",") : null,
    });
  }
  //Status only
  const active = obj?.status?.active;
  const activeUnderContract = obj?.status?.activeUnderContract;
  const closed = obj?.status?.closed;
  const commingSoon = obj?.status?.commingSoon;
  const pending = obj?.status?.pending;
  let k;
  if (active && commingSoon && !activeUnderContract && !closed && !pending) {
    k = null;
  } else {
    if (active || activeUnderContract || closed || commingSoon || pending) {
      const arrValue = [];
      if (active === true) arrValue.push("Active");
      if (activeUnderContract === true) arrValue.push("ActiveUnderContract");
      if (closed === true) arrValue.push("Closed");
      if (commingSoon === true) arrValue.push("CommingSoon");
      if (pending === true) arrValue.push("Pending");
      if (arrValue.length > 0 && closed !== true)
        arrayData.push({
          column: "Status",
          value: arrValue.join(","),
        });
      if (arrValue.length > 0 && closed === true) {
        arrayData.push({
          column: "Status",
          value: arrValue.join(","),
        });
        arrayData.push({
          column: "CloseDateFrom",
          operator: "ge",
          // value: "2024-02-15", //CLOSED DATE STATUS TO
          value: obj?.status?.closedDateFrom,
        });
        arrayData.push({
          column: "CloseDateTo",
          operator: "le",
          // value: "2024-05-15", //CLOSED DATE STATUS FROM
          value: obj?.status?.closedDateTo,
        });
      }
    }
  }
  //TimeFrame only
  const oneDay = obj?.timeFrame?.oneDay;
  const sevenDay = obj?.timeFrame?.sevenDay;
  const thirtyDay = obj?.timeFrame?.thirtyDay;
  if (oneDay || sevenDay || thirtyDay) {
    let value = null;
    if (oneDay) value = "ListedToday";
    if (sevenDay) value = "ListThisWeek";
    if (thirtyDay) value = "ListedThisMonth";
    arrayData.push({
      column: "Timeframe",
      value: [value].join(","),
    });
  }
  //Show only
  if (
    obj?.showOnly?.openHouses === true ||
    obj?.showOnly?.ourListings === true
  ) {
    let onlyOpenHouses = false;
    let onlyOurListings = false;
    if (
      obj?.showOnly?.openHouses === true &&
      obj?.showOnly?.ourListings !== true
    )
      onlyOpenHouses = true;
    if (
      obj?.showOnly?.openHouses !== true &&
      obj?.showOnly?.ourListings === true
    )
      onlyOurListings = true;
    arrayData.push({
      column: "ShowOnly",
      value: onlyOpenHouses
        ? "OpenHouses"
        : onlyOurListings
        ? "OurListings"
        : ["OpenHouses", "OurListings"].join(","),
    });
  }

  //Filter Map
  if (subdomainData) {
    if (
      obj?.map?.zoom &&
      subdomainData?.metro?.subdomain ===
        obj?.filterLocation?.metro?.subdomain &&
      !obj?.filterLocation?.country?.length > 0 &&
      !obj?.filterLocation?.city?.length > 0 &&
      !obj?.filterLocation?.postal?.length > 0 &&
      (!obj?.filterLocation?.region?.subdomain ||
        (obj?.filterLocation?.region?.subdomain &&
          subdomainData?.metro?.subdomain ===
            obj?.filterLocation?.metro?.subdomain))
    ) {
      arrayData.push({
        column: "Zoom",
        value: obj?.map?.zoom,
      });
    }

    if (
      obj?.map?.center?.lat &&
      subdomainData?.metro?.subdomain ===
        obj?.filterLocation?.metro?.subdomain &&
      !obj?.filterLocation?.country?.length > 0 &&
      !obj?.filterLocation?.city?.length > 0 &&
      !obj?.filterLocation?.postal?.length > 0 &&
      (!obj?.filterLocation?.region?.subdomain ||
        (obj?.filterLocation?.region?.subdomain &&
          subdomainData?.metro?.subdomain ===
            obj?.filterLocation?.metro?.subdomain &&
          !obj?.filterLocation?.country?.length > 0))
    ) {
      arrayData.push({
        column: "Lat",
        value: obj?.map?.center?.lat,
      });
    }

    if (
      obj?.map?.center?.lng &&
      subdomainData?.metro?.subdomain ===
        obj?.filterLocation?.metro?.subdomain &&
      !obj?.filterLocation?.country?.length > 0 &&
      !obj?.filterLocation?.city?.length > 0 &&
      !obj?.filterLocation?.postal?.length > 0 &&
      (!obj?.filterLocation?.region?.subdomain ||
        (obj?.filterLocation?.region?.subdomain &&
          subdomainData?.metro?.subdomain ===
            obj?.filterLocation?.metro?.subdomain &&
          !obj?.filterLocation?.country?.length > 0))
    ) {
      arrayData.push({
        column: "Lng",
        value: obj?.map?.center?.lng,
      });
    }
  }
  ///

  let str = "";
  if (arrayData.length === 0) return null;
  let arrayStr = arrayData.map((obj) => `${obj.column}=${obj.value}`).join("&");
  str = `?${arrayStr}`;
  return str;
};

const getLabel = (value) => {
  if (value == 10890) return "0.25 acres";
  if (value == 21780) return "0.5 acres";
  if (value == 32670) return "0.75 acres";
  if (value == 43560) return "1 acres";
  if (value == 87120) return "2 acres";
  if (value == 130680) return "3 acres";
  if (value == 174240) return "4 acres";
  if (value == 217800) return "5 acres";
  if (value == 435600) return "10 acres";
  if (value == 1742400) return "40 acres";
  if (value == 4356000) return "100 acres";
};

const mokedListLotSizeAcres2 = [
  // { value: 2000, label: "2000 sq.ft" },
  // { value: 4500, label: "4500 sq.ft" },
  // { value: 6500, label: "6500 sq.ft" },
  // { value: 8000, label: "8000 sq.ft" },
  { value: 10890, label: "0.25 acres" },
  { value: 21780, label: "0.5 acres" },
  { value: 32670, label: "0.75 acres" },
  { value: 43560, label: "1 acres" },
  { value: 87120, label: "2 acres" },
  { value: 130680, label: "3 acres" },
  { value: 174240, label: "4 acres" },
  { value: 217800, label: "5 acres" },
  { value: 435600, label: "10 acres" },
  { value: 1742400, label: "40 acres" },
  { value: 4356000, label: "100 acres" },
];

export const setFiltersFromUrl = (
  property,
  property_value,
  obj,
  callback,
  options,
  setFunctions,
  allQParams
) => {
  switch (property) {
    case "PropertyType":
      const temp = options.allPropertyTypes.map((type) => {
        if (type?.PropertyType === property_value) {
          return { ...type, show: true };
        } else return { ...type, show: false };
      });
      setFunctions.setAllPropertyTypes(temp);
      const newPropertyType = temp.filter(
        (type) => type.PropertyType === property_value
      );
      const newPropertySubTypeList = options.allSubPropertyTypes.filter(
        (subType) => subType.PropertyType === property_value
      );
      let newPropertySubType;
      if (allQParams?.PropertySubType) {
        let tempPropertySubType = [];
        if (allQParams?.PropertySubType.indexOf(",") <= -1) {
          tempPropertySubType.push(allQParams?.PropertySubType);
        } else {
          tempPropertySubType = [...allQParams?.PropertySubType.split(",")];
        }
        newPropertySubType = newPropertySubTypeList.filter((el) => {
          return tempPropertySubType.some((f) => {
            return f === el.PropertySubType;
          });
        });
        setFunctions.setResidentialSubType(newPropertySubType);
      }

      if (allQParams?.PropertySubType) {
        callback({
          ...obj,
          propertyType: newPropertyType,
          propertySubTypeList: newPropertySubTypeList,
          propertySubType: newPropertySubType,
        });
      } else {
        callback({
          ...obj,
          propertyType: newPropertyType,
          propertySubTypeList: newPropertySubTypeList,
        });
      }
      break;
    case "PropertySubType":
      // let tempPropertySubType = [];
      // if (property_value.indexOf(",") <= -1) {
      //     tempPropertySubType.push(property_value);
      // } else {
      //     tempPropertySubType = [...property_value.split(",")];
      // }

      // const propertySubTypeList = [...options.allSubPropertyTypes.filter(subType=>subType.PropertyType===allQParams.PropertyType)]
      // const newPropertySubType = propertySubTypeList.filter((el) => {
      //     return tempPropertySubType.some((f) => {
      //       return f === el.PropertySubType;
      //     });
      //   });

      // setFunctions.setResidentialSubType(newPropertySubType)
      // console.log(obj,'newPropertySubTypenewPropertySubType')
      // callback({
      //     ...obj,
      //     propertySubType: [...newPropertySubType]
      // })
      break;
    case "Region":
      const tempRegion = [
        ...options.filterLocationLists.regions?.filter((region) => {
          return region.subdomain === property_value;
        }),
      ];
      setFunctions.setRegion(tempRegion[0]);
      break;
    case "Metro":
      const tempMetro = [
        ...options.filterLocationLists.metros?.filter((metro) => {
          return metro.subdomain === property_value;
        }),
      ];
      setFunctions.setMetro(tempMetro[0]);
      break;
    case "County":
      let tempCountyList = [];
      if (property_value.indexOf(",") <= -1) {
        tempCountyList.push(property_value);
      } else {
        tempCountyList = [...property_value.split(",")];
      }
      const tempCounty = options.filterLocationLists.countries.filter((el) => {
        return tempCountyList.some((f) => {
          return f === el.subdomain;
        });
      });
      setFunctions.setCountry(tempCounty);
      break;
    case "City":
      let tempCityList = [];
      if (property_value.indexOf(",") <= -1) {
        tempCityList.push(property_value);
      } else {
        tempCityList = [...property_value.split(",")];
      }
      const tempCity = options.filterLocationLists.cities.filter((el) => {
        return tempCityList.some((f) => {
          return f === el.subdomain;
        });
      });
      setFunctions.setCity(tempCity);
      break;
    case "PostalCode":
      setFunctions.setPostal([...options.postalList]);
      break;
    case "ListPriceMin":
      if (allQParams?.ListPriceMin && allQParams?.ListPriceMax) {
        setFunctions.setPriceValue([
          allQParams?.ListPriceMin,
          allQParams?.ListPriceMax,
        ]);
      } else {
        setFunctions.setPriceValue([allQParams?.ListPriceMin, null]);
      }
      const tempSetMinMaxPriceMin = {
        value: +property_value,
        label: `$${parseInt(property_value)?.toLocaleString("en-US")}`,
      };
      setFunctions.setMinMaxPriceMin(tempSetMinMaxPriceMin);
      break;
    case "ListPriceMax":
      if (!allQParams?.ListPriceMin && allQParams?.ListPriceMax) {
        setFunctions.setPriceValue([null, allQParams?.ListPriceMax]);
      }
      const tempSetMinMaxPriceMax = {
        value: +property_value,
        label: `$${parseInt(property_value)?.toLocaleString("en-US")}`,
      };
      setFunctions.setMinMaxPriceMax(tempSetMinMaxPriceMax);
      break;
    case "BathroomsFullMin":
      setFunctions.setBathMin(+property_value);
      break;
    case "BathroomsFullMax":
      setFunctions.setBathMax(+property_value);
      break;
    case "LevelsMin":
      setFunctions.setLevelsMin(+property_value);
      break;
    case "LevelsMax":
      setFunctions.setLevelsMax(+property_value);
      break;
    case "BedroomsTotalMin":
      setFunctions.setBedsMin(+property_value);
      break;
    case "BedroomsTotalMax":
      setFunctions.setBedsMax(+property_value);
      break;
    case "AboveGradeFinishedAreaMin":
      setFunctions.setSqftMin(+property_value);
      break;
    case "AboveGradeFinishedAreaMax":
      setFunctions.setSqftMax(+property_value);
      break;
    case "YearBuiltMin":
      setFunctions.setYearBuiltMin(+property_value);
      break;
    case "YearBuiltMax":
      setFunctions.setYearBuiltMax(+property_value);
      break;
    case "GarageSpacesMin":
      setFunctions.setGaragesSpacesMin(+property_value);
      break;
    case "GarageSpacesMax":
      setFunctions.setGaragesSpacesMax(+property_value);
      break;
    case "LotSizeAcresMin":
      const tempAcresMin = mokedListLotSizeAcres2.filter(
        (ac) => ac.value === +property_value
      )[0];
      setFunctions.setLotSizeAcresMin(tempAcresMin);
      break;
    case "LotSizeAcresMax":
      const tempAcresMax = mokedListLotSizeAcres2.filter(
        (ac) => ac.value === +property_value
      )[0];
      setFunctions.setLotSizeAcresMax(tempAcresMax);
      break;
    case "MainLevelPrimary":
      setFunctions.setIsPrimaryBedOnMain(!!property_value);
      break;
    case "Fireplace":
      setFunctions.setIsFireplace(!!property_value);
      break;
    case "AccessibilityFeatures":
      setFunctions.setIsAccessibilityFeatures(!!property_value);
      break;
    case "PoolPrivateYN":
      setFunctions.setIsPoolFeatures(!!property_value);
      break;

    case "Status":
      if (property_value?.includes("CommingSoon")) {
        setFunctions.setIsCommingSoon(true);
      }
      if (property_value?.includes("Active")) {
        setFunctions.setIsActive(true);
      }
      if (property_value?.includes("ActiveUnderContract")) {
        setFunctions.setIsActiveUnderContract(true);
      }
      if (property_value?.includes("Pending")) {
        setFunctions.setIsPending(true);
      }
      if (property_value?.includes("Closed")) {
        setFunctions.setIsClosed(true);
        setFunctions.setDateFrom(allQParams?.CloseDateFrom);
        setFunctions.setDateTo(allQParams?.CloseDateTo);
      }
      break;
    case "Timeframe":
      if (property_value === "ListedToday") {
        setFunctions.setOneDay(true);
      }
      if (property_value === "ListThisWeek") {
        setFunctions.setSevenDay(true);
      }
      if (property_value === "ListedThisMonth") {
        setFunctions.setThirtyDay(true);
      }
      break;
    case "ShowOnly":
      if (property_value?.includes("OurListings")) {
        setFunctions.setIsOurListings(true);
      }
      if (property_value?.includes("OpenHouses")) {
        setFunctions.setIsOpenHouses(true);
      }
      break;
    case "Zoom":
      setFunctions.setMapZoom(+property_value);
      break;
    case "Lng":
      const lng = +property_value;
      const lat = +allQParams?.Lat;
      setFunctions.setCurrentCenter({ lat: lat, lng: lng });
      break;
    default:
      return;
  }
};
