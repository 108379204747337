import React, { useState } from "react";
import { colors } from "../../../constants/colors";
import { ArrowDown, ArrowUp } from "../../../constants/icons";
import ClickOutside from "../../clickOutside/clickoutSideComponent";
import "./baseInputList.css";

const BaseInputList = ({
  readonly = false,
  name = "sorting",
  className,
  classNameList,
  onClick,
  list,
  placeholder = "Sort By Default",
  colorEndIcon = colors.mainBg,
  height,
  listTop = "54px",
  padding,
  field = null,
  setInputValue,
  inputValue,
  setInValue2,
  blueList = false,
  initValue,
  topList = false,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const inputHandler = () => {
    setIsVisible(true);
  };

  const clickOutsideHandler = () => {
    setIsVisible(false);
  };

  const onIconItem = (item) => {
    setInputValue(item);
    setIsVisible(false);
    if (setInValue2) setInValue2(item);
  };

  return (
    <div
      className="filters-page-base-input-list-wrapper"
      onClick={() => {
        inputHandler();
        if (onClick) onClick();
      }}
    >
      <div className="filters-page-base-input-list-end-icon-wrapper">
        <ArrowUp
          color={colorEndIcon}
          style={{ display: !isVisible ? "none" : "block" }}
        />
      </div>
      <ClickOutside onClick={clickOutsideHandler} style={{ height: height }}>
        <div className="filters-page-base-input-list-end-icon-wrapper">
          <ArrowDown
            color={colorEndIcon}
            style={{ display: isVisible ? "none" : "block" }}
          />
        </div>
        <input
          name={name}
          type="text"
          placeholder={placeholder}
          className={className}
          readOnly={readonly}
          value={inputValue[field] ? inputValue[field] : inputValue}
          style={{
            width: "100%",
            height: height,
            borderBottomRightRadius: isVisible ? 0 : 10,
            borderBottomLeftRadius: isVisible ? 0 : 10,
            padding: padding ? padding : "19px 24px",
          }}
        />
      </ClickOutside>
      <ul
        className={
          topList
            ? "filters-page-base-input-list-ul-top"
            : "filters-page-base-input-list-ul"
        }
        style={{ display: isVisible ? "block" : "none", top: listTop }}
      >
        {list.map((item, i) => {
          if (field) {
            return (
              <li
                key={i}
                onClick={() => onIconItem(item)}
                className={classNameList}
              >
                <p
                  className={`${
                    (item[field] === inputValue[field] && blueList) ||
                    (inputValue === "" && i === 0)
                      ? "filters-page-base-input-list-blue-wrapper"
                      : ""
                  }`}
                >
                  {item[field]}
                </p>
              </li>
            );
          } else {
            return (
              <li
                key={i}
                onClick={() => onIconItem(item)}
                className={classNameList}
              >
                <p
                  className={`${
                    item === inputValue && blueList
                      ? "filters-page-base-input-list-blue-wrapper"
                      : ""
                  }`}
                  // style={{
                  //   color: item === inputValue && blueList ? "blue" : "gray",
                  // }}
                >
                  {item}
                </p>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default BaseInputList;
