import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactSlider from "react-slider";
import { colors } from "../../../constants/colors";
import { AttributeIcon, CloseIcon } from "../../../constants/icons";
import { usePriceMinMaxData } from "../../../contexts";
import { BaseInputList } from "../../../ui";
import "./HeaderLayerPrice.css";

const HeaderLayerPrice = ({
  isPopUpShowPrice,
  setIsPopUpShowPrice,
  setIsPopUpShowSpecs,
  setIsPopUpShowType,
  setIsPopUpShowLoc,
  applyHandler,
  priceInfoLoading,
  setPriceInfoLoading,
  filters,
}) => {
  const [applied, setApplied] = useState(null);
  const { isOpenNarrowFilters } = useSelector((state) => state.isResetFilters);
  const { minMaxPriceMin, minMaxPriceMax } = usePriceMinMaxData();

  useEffect(() => {
    let counter = null;
    if (minMaxPriceMin.label !== "No Min") {
      counter++;
    }
    if (minMaxPriceMax.label !== "No Max") {
      counter++;
    }
    setApplied(counter);
  }, [isPopUpShowPrice, isOpenNarrowFilters]);

  const headerLayerLocationHandler = () => {
    setIsPopUpShowPrice(true);
    setIsPopUpShowSpecs(false);
    setIsPopUpShowType(false);
    setIsPopUpShowLoc(false);
  };

  const headerLayerPopupHandler = () => {
    setIsPopUpShowPrice(false);
  };

  return (
    <div className="HeaderLayerPrice-wrapper">
      <div
        className="HeaderLayerPrice-wrapper-content"
        onClick={headerLayerLocationHandler}
      >
        <div className="HeaderLayerPrice-wrapper-content-title">Price</div>
        {applied && (
          <div className="HeaderLayerPrice-wrapper-content-box">
            <div>{applied}</div>
          </div>
        )}
        <div className="HeaderLayerPrice-wrapper-content-title-icon">
          <div>
            <AttributeIcon />
          </div>
        </div>
        {/* <div className="HeaderLayerPrice-wrapper-content-box">
          {applied ? (
            `${applied} Applied`
          ) : (
            <span className="HeaderLayerPrice-wrapper-content-box-none">
              None
            </span>
          )}
        </div> */}
      </div>
      {isPopUpShowPrice && (
        <PricePopup
          headerLayerPopupHandler={headerLayerPopupHandler}
          applyHandler={applyHandler}
          priceInfoLoading={priceInfoLoading}
          setPriceInfoLoading={setPriceInfoLoading}
          filters={filters}
        />
      )}
    </div>
  );
};

export default HeaderLayerPrice;

const PricePopup = ({
  headerLayerPopupHandler,
  applyHandler,
  priceInfoLoading,
  setPriceInfoLoading,
  filters,
}) => {
  const { setMinMaxPriceMin, setMinMaxPriceMax, setPriceValue } =
    usePriceMinMaxData();
  const resetLocationHandler = () => {
    setMinMaxPriceMin({
      value: 75000,
      label: "$75,000",
    });
    setMinMaxPriceMax({
      value: 1000000,
      label: "$1,000,000",
    });
    setPriceValue([75000, 1000000]);
  };

  return (
    <div className="PricePopup-wrapper">
      <div className="PricePopup-wrapper-box">
        <div className="PricePopup-wrapper-header-wrapper">Price Filter</div>
        <div
          className="PricePopup-wrapper-header-close"
          onClick={headerLayerPopupHandler}
        >
          <CloseIcon color={colors.mainBg} />
        </div>
        <div className="PricePopup-wrapper-header-content-block">
          <PricePopupPrice
            priceInfoLoading={priceInfoLoading}
            setPriceInfoLoading={setPriceInfoLoading}
          />
        </div>
        <div className="PricePopup-wrapper-header-btns-block">
          <PriceReset
            resetLocationHandler={resetLocationHandler}
            headerLayerPopupHandler={headerLayerPopupHandler}
          />
          <PriceApply
            applyHandler={applyHandler}
            headerLayerPopupHandler={headerLayerPopupHandler}
            filters={filters}
          />
        </div>
      </div>
    </div>
  );
};

const PricePopupPrice = ({ priceInfoLoading }) => {
  const {
    minValues,
    setMinValues,
    maxValues,
    setMaxValues,
    minValue,
    setMinValue,
    maxValue,
    setMaxValue,
    minMaxPriceMin,
    setMinMaxPriceMin,
    minMaxPriceMax,
    setMinMaxPriceMax,
    sliderValues,
    setSliderValues,
    sliderDefaultValues,
    setSliderDefaultValues,
    stepValue,
    setStepValue,
    propertyTypePrice,
    setPropertyTypePrice,
    priceValue,
    setPriceValue,
  } = usePriceMinMaxData();

  const handleSliderChange = (newValues) => {
    setSliderValues([newValues[0], newValues[1]]);
    setPriceValue([newValues[0], newValues[1]]);
  };
  return (
    <div className="filters-block-filter-filter-location-content">
      <div className="filters-block-filter-details-input-list-block-title">
        <p>Price</p>
      </div>

      <div className="filters-block-filter-details-input-list-block-price-values">
        {priceValue[0] === 0 && priceValue[1] === 50000000
          ? "Any Price"
          : `$${
              sliderValues[0]
                ? sliderValues[0].toLocaleString("en-US")
                : priceValue[0]?.toLocaleString("en-US")
            } - $${
              sliderValues[1]
                ? sliderValues[1].toLocaleString("en-US")
                : priceValue[1]?.toLocaleString("en-US")
            }`}
      </div>
      <div>
        <ReactSlider
          disabled={priceInfoLoading ? true : false}
          min={minValue}
          max={maxValue}
          value={sliderValues}
          onChange={handleSliderChange}
          step={stepValue}
          className="horizontal-slider"
          thumbClassName="example-thumb"
          trackClassName="example-track"
          defaultValue={sliderDefaultValues}
          minDistance={10}
        />
      </div>
      <div className="filters-block-filter-min-max-Price-input-list-block filters-block-filter-min-max-Price-addedMarginTop65">
        <div className="filters-block-filter-min-max-Price-list-block-content">
          <div className="filters-block-filter-details-input-list-block-title">
            <p>Set Min</p>
          </div>
          <div className="filters-block-filter-details-input-list-block-title">
            <p>Set Max</p>
          </div>
        </div>
      </div>
      <div className="filters-block-filter-min-max-Price-input-list-block">
        <div className="filters-block-filter-min-max-Price-list-block-content">
          <div className="filters-block-filter-details-input-list-block-input">
            <BaseInputList
              blueList={true}
              className={`${
                minMaxPriceMin.value !== null || minMaxPriceMin.value === 0
                  ? "filters-block-filter-details-input-list-block-input-blue-color"
                  : "filters-block-filter-details-input-list-block-input-gray-color"
              }`}
              classNameList="filters-block-filter-details-input-list-block-input-gray-color"
              readonly={true}
              list={
                minMaxPriceMax.value !== null
                  ? [
                      { value: null, label: "No Min" },
                      { value: 0, label: "$0" },
                      ...minValues.filter(
                        (item) => item.value <= minMaxPriceMax.value
                      ),
                    ]
                  : [
                      { value: null, label: "No Min" },
                      { value: 0, label: "$0" },
                      ...minValues,
                    ]
              }
              placeholder="No Min"
              listTop="-219px"
              field="label"
              inputValue={minMaxPriceMin}
              setInputValue={setMinMaxPriceMin}
              height={50}
              isLoading={priceInfoLoading}
              toLocaleString={true}
              topList={true}
            />
          </div>
          <div className="filters-block-filter-details-input-list-block-input">
            <BaseInputList
              blueList={true}
              className={`${
                minMaxPriceMax.value !== null
                  ? "filters-block-filter-details-input-list-block-input-blue-color"
                  : "filters-block-filter-details-input-list-block-input-gray-color"
              }`}
              classNameList="filters-block-filter-details-input-list-block-input-gray-color"
              readonly={true}
              list={[
                { value: null, label: "No Max" },
                ...maxValues.filter(
                  (item) => item.value >= minMaxPriceMin.value
                ),
              ]}
              listTop="-219px"
              field="label"
              inputValue={minMaxPriceMax}
              setInputValue={setMinMaxPriceMax}
              height={50}
              isLoading={priceInfoLoading}
              toLocaleString={true}
              topList={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const PriceApply = ({ applyHandler, headerLayerPopupHandler, filters }) => {
  const [isPriceApply, setIsPriceApply] = useState({
    isActive: null,
    data: null,
  });

  const locationApplyHandler = () => {
    setIsPriceApply({ isActive: true, data: !isPriceApply.data });
  };

  useEffect(() => {
    if (isPriceApply.isActive) {
      applyHandler();
      headerLayerPopupHandler();
    }
  }, [isPriceApply.data, filters?.homeFeatures?.setMinMaxPrice]);
  // , filters?.homeFeatures?.setMinMaxPrice]

  return (
    <div className="PriceApply-wrapper" onClick={locationApplyHandler}>
      <div className="PriceApply-apply-wrapper">
        <div>Apply</div>
      </div>
    </div>
  );
};

const PriceReset = ({ headerLayerPopupHandler, resetLocationHandler }) => {
  const locationResetHandler = () => {
    resetLocationHandler();
    // headerLayerPopupHandler();
  };

  return (
    <div className="PriceReset-wrapper" onClick={locationResetHandler}>
      <div className="PriceReset-apply-wrapper">
        <div>Clear</div>
      </div>
    </div>
  );
};
