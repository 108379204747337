/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import "./filtersPageBlock.css";
import "./responsive.css";
import Map from "../../components/Map/Map";
import SortingBlock from "../SortingBlock/SortingBlock.jsx";
import FiltersBlock from "../FiltersBlock/FiltersBlock.jsx";
import {
  ButtonsBlock,
  FilterLocation,
  HomeFeatures,
  PropertySubType,
  PropertyType,
  ShowOnly,
  SortingInputBlock,
  Status,
  Timeframe,
  TooltipMessage,
} from "../../components";
import {
  CloseIcon,
  CubeIcon,
  CubeIconBig4,
  FiltersIcon,
  ListIcon,
} from "../../constants/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateIsOpenNarrowFilters } from "../../store/actions/updateIsOpenNarrowFilters";
import { colors } from "../../constants/colors";
import { useScreenSize } from "../../hooks";
import { QuickSearch } from "../../components/FilterBlocks";
import { toObjFilterConverter } from "../../constants/methods";
import TripleSwitcherSliderButton from "../../ui/TripleToggleViewButton/TripleSwitcherSliderButton";
import { setIsMap } from "../../store/actions/setIsMap";
import { useSearchParams } from "react-router-dom";
import SortingHeaderPagination from "../../components/SortingHeaderPagination/sortingHeaderPagination";
import { resetMapCoordinates } from "../../store/actions/resetMapCoordinates";
import Pagination from "../../components/Pagination/ReactPagination";
import { mokedMapViewPagesList } from "../../constants/viewMapPagesList";
import { getAllHouseCardsAction } from "../../store/actions/getAllHouseCards";
import { getAllBothViewMapHouseCards } from "../../store/actions/getAllBothViewMapHouseCards";
import { getMapCoordinates } from "../../constants/mapFunctions";

const FiltersPageBlock = ({
  container,
  isPicturesFeature,
  allPropertyTypes,
  allSubPropertyTypes,
  setAllPropertyTypes,
  filters,
  setFilters,
  residentialSubType,
  setResidentialSubType,
  foundResidentialSubType,
  setFoundResidentialSubType,
  sortingValue,
  setSortingValue,
  currentPage,
  setCurrentPage,
  isApply,
  setIsApply,
  applyHandler,
  isOpenHouses,
  setIsOpenHouses,
  isOurListings,
  setIsOurListings,
  MLS_address,
  setMLS_Address,
  MLS_addressRes,
  setMLS_AddressRes,
  foundMLS_address,
  setFoundMLS_address,
  region,
  setRegion,
  metro,
  setMetro,
  country,
  setCountry,
  city,
  setCity,
  postal,
  setPostal,
  foundRegions,
  setFoundRegions,
  foundMetro,
  setFoundMetro,
  foundCountry,
  setFoundCountry,
  foundCity,
  setFoundCity,
  foundPostal,
  setFoundPostal,
  sqftMin,
  setSqftMin,
  yearBuiltMin,
  setYearBuiltMin,
  garagesSpacesMin,
  setGaragesSpacesMin,
  bathMin,
  setBathMin,
  levelsMin,
  setLevelsMin,
  bedsMin,
  setBedsMin,
  lotSizeAcresMin,
  setLotSizeAcresMin,
  sqftMax,
  setSqftMax,
  yearBuiltMax,
  setYearBuiltMax,
  garagesSpacesMax,
  setGaragesSpacesMax,
  bathMax,
  setBathMax,
  levelsMax,
  setLevelsMax,
  bedsMax,
  setBedsMax,
  lotSizeAcresMax,
  setLotSizeAcresMax,
  isPrimaryBedOnMain,
  setIsPrimaryBedOnMain,
  isFireplace,
  setIsFireplace,
  isAccessibilityFeatures,
  setIsAccessibilityFeatures,
  isPoolFeatures,
  setIsPoolFeatures,
  oneDay,
  setOneDay,
  sevenDay,
  setSevenDay,
  thirtyDay,
  setThirtyDay,
  isCommingSoon,
  setIsCommingSoon,
  isActive,
  setIsActive,
  isActiveUnderContract,
  setIsActiveUnderContract,
  isPending,
  setIsPending,
  isClosed,
  setIsClosed,
  resetHandler,
  mapZoom,
  setMapZoom,
  currentCenter,
  setCurrentCenter,
  polygonData,
  setPolygonData,
  polygonsData,
  setPolygonsData,
  currentPolygon,
  setCurrentPolygon,
  currentPolygons,
  setCurrentPolygons,
  subdomainData,
  isOpenHousesUrl,
  dataInRectPage,
  setDataInRectPage,
  currentLayersPolygon,
  setCurrentLayersPolygon,
  currentLayersPolygons,
  setCurrentLayersPolygons,
  isDraw,
  setIsDraw,
  dataInPolyline,
  setDataInPolyline,
  dataInMultyPolygones,
  setDataInMultyPolygones,
  drawCoordinates,
  setDrawCoordinates,
  setIsMultyDraw,
  isMultyDraw,
  isFirstLoadedMinMaxQPar,
  setIsFirstLoadedMinMaxQPar,
  queryParamsObject,
  filterLocationRegions,
  filterLocationMetros,
  filterLocationCounties,
  twCities,
  setTwCities,
  twPostals,
  setTwPostals,
  twCitiesPage,
  setTwCitiesPage,
  twPostalsPage,
  setTwPostalsPage,
  currentMap,
  setCurrentMap,
  currentMaps,
  setCurrentMaps,
  priceInfoLoading,
  setPriceInfoLoading,
  currentCenterCoord,
  setCurrentCenterCoord,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  state,
  setState,
  getSwitchAnimation,
  isAllOurListings,
  setIsAllOurListings,
  visibleHouseCards,
}) => {
  const isMap = useSelector((state) => state.isMap);
  const { isOpenNarrowFilters } = useSelector((state) => state.isResetFilters);
  //map
  // const [currentMap, setCurrentMap] = useState(null);

  //need remove it
  const [residential, setResidential] = useState(true);
  const [residentialLease, setResidentialLease] = useState(false);
  const [ResidentialIncome, setResidentialIncome] = useState(false);
  const [commercialSale, setCommercialSale] = useState(false);
  const [commercialLease, setCommercialLease] = useState(false);
  const [farmAndRanch, setFarmAndRanch] = useState(false);
  const [land, setLand] = useState(false);

  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);

  const dispatch = useDispatch();

  const openNarrowFiltersHandle = () => {
    dispatch(updateIsOpenNarrowFilters(true));
    window.scrollTo(0, 0);
  };

  const onIconClick = (icon) => {
    if (icon === "icon_list") {
      setIsActive1(true);
      setIsActive2(false);
      setIsActive3(false);
    } else if (icon === "icon_4") {
      setIsActive1(false);
      setIsActive2(false);
      setIsActive3(false);
    } else {
      setIsActive2(true);
      setIsActive3(true);
    }
  };

  const { data } = useSelector((state) => state.houseCards);
  const mapCoordinates = useSelector((state) => state.mapCoordinates);
  const houseCardDataInRect = useSelector((state) => state.houseCardsMap);

  // const visibleHouseCards = useMemo(() => {
  //   return data?.listings;
  // }, [data?.listings, isApply]);

  useEffect(() => {
    if (isMap.data === null) {
      setCurrentPage(0);
    }
  }, [mapCoordinates]);

  useEffect(() => {
    if (isMap.data === null) {
      let tempOrder = null;
      if (
        sortingValue?.orderBySort === "desc" ||
        sortingValue?.orderBySort === "asc"
      ) {
        tempOrder = `${sortingValue.value} ${sortingValue.orderBySort}`;
      }
      const filtedrArr = toObjFilterConverter(filters);
      // TODO I removed it because I'll use the same data for map and grid(not separate)
      // dispatch(
      //   getAllHouseCardsMapAction(
      //     currentPage + 1,
      //     mapCoordinates?.data,
      //     filtedrArr,
      //     tempOrder
      //   )
      // );
      // TODO its for both in Both View
    }
  }, [mapCoordinates, currentPage]);

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const queryParamsObject = {};
  // queryParams.forEach((value, key) => {
  //   queryParamsObject[key] = value;
  // });

  const [sliderState, setSliderState] = useState("");
  const [isFirstLoadViewParam, setIsFirstLoadViewParam] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [initSliderState, setInitSliderState] = useState(false);

  const setViewToUrl = (view) => {
    searchParams.set("View", view);
    setSearchParams(searchParams);
  };

  const listHandle = () => {
    dispatch(setIsMap(false));
    setSliderState(false);
    if (isFirstLoadViewParam) setViewToUrl("list");
    if (isFirstLoadViewParam === false) setIsFirstLoadViewParam(true);
  };
  const mapHandle = () => {
    dispatch(setIsMap(true));
    setSliderState(true);
    if (isFirstLoadViewParam) setViewToUrl("map");
    if (isFirstLoadViewParam === false) setIsFirstLoadViewParam(true);
  };
  const bothHandle = () => {
    dispatch(setIsMap(null));
    setSliderState(null);
    if (isFirstLoadViewParam) setViewToUrl("both");
    if (isFirstLoadViewParam === false) setIsFirstLoadViewParam(true);
  };

  useEffect(() => {
    let view = "list";
    if (initSliderState) {
      if (isMap.data === null) view = "both";
      if (isMap.data === true) view = "map";
      setViewToUrl(view);
    } else {
      setInitSliderState(true);
    }
  }, [isApply]);

  useEffect(() => {
    if (state.switchPosition === "left") {
      listHandle();
      // setIsDraw(false);
    }
    if (state.switchPosition === "center") {
      mapHandle();
    }
    if (state.switchPosition === "right") {
      bothHandle();
    }
  }, [state.switchPosition]);

  const screenSize = useScreenSize();

  useEffect(() => {
    if (screenSize[0] > 1199 && screenSize[0] < 1441) {
      setIsActive1(false);
      setIsActive2(false);
      setIsActive3(false);
    }
    if (
      screenSize[0] < 1080 &&
      screenSize[0] > 0 &&
      state.switchPosition === "right"
    ) {
      setState({ switchPosition: "left", animation: "right-to-left" });
    }
  }, [screenSize[0]]);

  const mapBlockRef = useRef(null);
  const [mapIsVisible40, setMapIsVisible40] = useState(true);
  const [mapIsVisible40Loaded, setMapIsVisible40Loaded] = useState(null);

  const observerMap = () => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          // The block is visible, do something
          // console.log("Block is visible");
          setMapIsVisible40(true);
        } else {
          // The block is hidden, do something else
          // console.log("Block is hidden");
          setMapIsVisible40(false);
        }
      });
    }, options);
    // Cleanup
    if (mapBlockRef.current) {
      observer.observe(mapBlockRef.current);
    }
    return () => {
      if (mapBlockRef.current) {
        observer.unobserve(mapBlockRef.current);
      }
    };
  };

  //   useEffect(() => {
  //     if (currentMap && !mapIsVisible40Loaded) {
  //       observerMap();
  //       setMapIsVisible40Loaded(true);
  //     }
  //   }, [currentMap]);

  const [narrowMarginTop, setNarrowMarginTop] = useState("");
  const [mapPositionLess430, setMapPositionLess430] = useState("initial");
  const [topListings, setTopListings] = useState(0);
  const [topViews, setTopViews] = useState(0);
  useEffect(() => {
    if (screenSize[1] > 430) {
      if (screenSize[0] > 1199) {
        if (isMap.data === null) {
          setNarrowMarginTop("calc(90px + 40svh)");
        } else {
          setNarrowMarginTop("90px");
          // setNarrowMarginTop("13px");
        }
      }
      if (screenSize[0] <= 1199 && screenSize[0] > 768) {
        if (isMap.data === null) {
          //   setNarrowMarginTop("calc(188px + 40svh)");
          setNarrowMarginTop("calc(110px + 40svh)");
          setTopListings("78px");
        } else {
          setNarrowMarginTop("108px");
          //   setNarrowMarginTop("31px");
          setTopListings("80px");
        }
      }
      if (screenSize[0] > 993) {
        setTopViews("0px");
      }
      if (screenSize[0] <= 768) {
        if (isMap.data === null) {
          //   setNarrowMarginTop("calc(199px + 40svh)");
          // setNarrowMarginTop("calc(124px + 40svh)");
          setNarrowMarginTop("calc(144px + 40svh)");
          setTopViews("82px");
          setTopListings("88px");
        } else {
          setNarrowMarginTop("121px");
          //   setNarrowMarginTop("44px");
          setTopViews("82px");
          setTopListings("88px");
        }
      }
      if (screenSize[0] <= 440) {
        if (isMap.data === null) {
          setNarrowMarginTop("calc(192px + 40svh)");
          setTopListings("132px");
        }
        // else {
        //   setNarrowMarginTop("169px");
        // }
      }
      if (isMap.data === null || isMap.data === true) {
        setMapPositionLess430("fixed");
      } else {
        setMapPositionLess430("initial");
      }
    } else {
      if (isMap.data === null || isMap.data === true) {
        setMapPositionLess430("initial");
      }
      if (screenSize[0] <= 768) {
        if (isMap.data === false) {
          setTopListings("80px");
          setTopViews("74px");
          setNarrowMarginTop("0px");
        } else {
          setTopListings("calc(40svh + 80px)");
          setTopViews("calc(40svh + 74px)");
          setNarrowMarginTop("0px");
        }
      }

      if (screenSize[0] <= 440) {
        if (isMap.data === null) {
          setNarrowMarginTop("96px");
          setTopListings("calc(120px + 40svh)");
        }
        // else {
        //   setNarrowMarginTop("48px");
        // }
      }

      if (screenSize[0] > 768 && screenSize[0] <= 993) {
        if (isMap.data !== null) {
          setTopListings("80px");
          //   setTopListings("157px");
          // setTopViews("151px")
          setNarrowMarginTop("0px");
        } else {
          setTopListings("calc(40svh + 80px)");
          //   setTopListings("calc(40svh + 157px)");
          setTopViews("calc(40svh + 151px)");
          setNarrowMarginTop("0px");
        }
      }

      if (screenSize[0] > 993 && screenSize[0] <= 1199) {
        if (isMap.data !== null) {
          setTopListings("80px");
          //   setTopListings("157px");
          setTopViews("0px");
          setNarrowMarginTop("0px");
        } else {
          setTopListings("calc(40svh + 80px)");
          //   setTopListings("calc(40svh + 157px)");
          setTopViews("0px");
          setNarrowMarginTop("0px");
        }
      }

      if (screenSize[0] >= 1200 && screenSize[0] < 1440) {
        if (isMap.data !== null) {
          setTopListings("0px");
          setTopViews("0px");
          setNarrowMarginTop("0px");
        } else {
          setTopListings("0");
          setTopViews("0px");
          setNarrowMarginTop("0px");
        }
      }

      if (screenSize[0] >= 1440 && screenSize[0] < 1920) {
        if (isMap.data !== null) {
          setTopListings("0px");
          setTopViews("0px");
          setNarrowMarginTop("0px");
        } else {
          setTopListings("0");
          setTopViews("0px");
          setNarrowMarginTop("0px");
        }
      }

      if (screenSize[0] >= 1920) {
        if (isMap.data !== null) {
          setTopListings("0px");
          setTopViews("0px");
          setNarrowMarginTop("0px");
        } else {
          setTopListings("0");
          setTopViews("0px");
          setNarrowMarginTop("0px");
        }
      }
    }
  }, [isMap.data, screenSize]);

  useEffect(() => {
    if (isMap.data === false) {
      setCurrentMap(null);
    }
  }, [isMap.data]);

  const getSortedData = () => {
    const filtedrArr = toObjFilterConverter(filters);
    let tempOrder = null;
    if (
      sortingValue?.orderBySort === "desc" ||
      sortingValue?.orderBySort === "asc"
    ) {
      tempOrder = `${sortingValue.value} ${sortingValue.orderBySort}`;
    }

    dispatch(getAllHouseCardsAction(1, filtedrArr, tempOrder));
    dispatch(
      getAllBothViewMapHouseCards(
        mapCoordinates?.data,
        filtedrArr,
        tempOrder,
        dataInRectPage.value
      )
    );
  };

  useEffect(() => {
    if (sortingValue?.value) {
      getSortedData();

      const PropertiesOrderByEvent = new CustomEvent("PropertiesOrderByEvent", {
        detail: {
          Listing: sortingValue.value,
        },
      });
      window.document.dispatchEvent(PropertiesOrderByEvent);
    }
  }, [sortingValue]);

  const [isLoadedDataDisplaying, setIsLoadedDataDisplaying] = useState(false);
  const [isLoadedMinMax, setIsLoadedMinMax] = useState(false);

  useEffect(() => {
    setIsLoadedDataDisplaying(true);
  }, [isMap.data]);

  useEffect(() => {
    if (
      filters.homeFeatures.setMinMaxPrice?.max &&
      filters.homeFeatures.setMinMaxPrice?.min &&
      !isLoadedMinMax
    ) {
      setIsLoadedMinMax(true);
    }
  }, [filters.homeFeatures.setMinMaxPrice]);

  useEffect(() => {
    if (isLoadedMinMax) {
      let tempOrder = null;
      if (
        sortingValue?.orderBySort === "desc" ||
        sortingValue?.orderBySort === "asc"
      ) {
        tempOrder = `${sortingValue.value} ${sortingValue.orderBySort}`;
      }
      const filtedrArr = toObjFilterConverter(filters);
      if (
        mapCoordinates?.data?.EndPoint?.lat &&
        mapCoordinates?.data?.NorthEast?.lat !==
          mapCoordinates?.data?.SouthWest?.lat
      ) {
        dispatch(
          getAllBothViewMapHouseCards(
            mapCoordinates?.data,
            filtedrArr,
            tempOrder,
            dataInRectPage?.value,
            setIsLoadedDataDisplaying
          )
        );
      }
    }
  }, [
    isLoadedMinMax,
    mapCoordinates?.data?.EndPoint?.lat,
    mapCoordinates?.data?.EndPoint?.lng,
    dataInRectPage?.value,
  ]);

  useEffect(() => {
    if (
      ((isDraw === null && isMultyDraw === false) ||
        (isDraw === false && isMultyDraw === null) ||
        (isDraw === false && isMultyDraw === false)) &&
      currentMap
    ) {
      let coordinates = getMapCoordinates(currentMap);
      const obj = {
        NorthEast: coordinates.NECorner,
        SouthEast: coordinates.SECorner,
        SouthWest: coordinates.SWCorner,
        NorthWest: coordinates.NWCorner,
        EndPoint: coordinates.NWCorner,
      };
      // setRectangleMapCoordinates(obj);

      let tempOrder = null;
      if (
        sortingValue?.orderBySort === "desc" ||
        sortingValue?.orderBySort === "asc"
      ) {
        tempOrder = `${sortingValue.value} ${sortingValue.orderBySort}`;
      }
      const filtedrArr = toObjFilterConverter(filters);
      dispatch(
        getAllBothViewMapHouseCards(
          obj,
          filtedrArr,
          tempOrder,
          // dataInRectPage.value
          1
        )
      );
    }
  }, [isDraw, isMultyDraw]);

  return (
    <>
      <div id="new-wrapper">
        <div
          style={{
            display: isOpenNarrowFilters
              ? // (isMap.data !== null || screenSize[0] < 801)
                "block"
              : "none",
            position: "fixed",
            top: 77,
          }}
          className={
            isMap.data === null
              ? "new-FiltersBlockNarrow-map new-FiltersBlockNarrow-map-both"
              : isMap.data === false
              ? "new-FiltersBlockNarrow-map new-FiltersBlockNarrow-map-list"
              : "new-FiltersBlockNarrow-map new-FiltersBlockNarrow-map-map"
          }
        >
          <FiltersBlockNarrow
            filters={filters}
            setFilters={setFilters}
            setIsApply={setIsApply}
            isApply={isApply}
            sortingValue={sortingValue}
            residential={residential}
            setResidential={setResidential}
            residentialLease={residentialLease}
            setResidentialLease={setResidentialLease}
            ResidentialIncome={ResidentialIncome}
            setResidentialIncome={setResidentialIncome}
            commercialSale={commercialSale}
            setCommercialSale={setCommercialSale}
            commercialLease={commercialLease}
            setCommercialLease={setCommercialLease}
            farmAndRanch={farmAndRanch}
            setFarmAndRanch={setFarmAndRanch}
            land={land}
            setLand={setLand}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            MLS_address={MLS_address}
            MLS_addressRes={MLS_addressRes}
            foundMLS_address={foundMLS_address}
            setMLS_Address={setMLS_Address}
            setMLS_AddressRes={setMLS_AddressRes}
            setFoundMLS_address={setFoundMLS_address}
            region={region}
            metro={metro}
            country={country}
            city={city}
            postal={postal}
            setRegion={setRegion}
            setMetro={setMetro}
            setCountry={setCountry}
            setCity={setCity}
            setPostal={setPostal}
            setFoundRegions={setFoundRegions}
            setFoundMetro={setFoundMetro}
            setFoundCountry={setFoundCountry}
            setFoundCity={setFoundCity}
            setFoundPostal={setFoundPostal}
            foundRegions={foundRegions}
            foundMetro={foundMetro}
            foundCountry={foundCountry}
            foundCity={foundCity}
            foundPostal={foundPostal}
            allPropertyTypes={allPropertyTypes}
            allSubPropertyTypes={allSubPropertyTypes}
            setAllPropertyTypes={setAllPropertyTypes}
            residentialSubType={residentialSubType}
            setResidentialSubType={setResidentialSubType}
            foundResidentialSubType={foundResidentialSubType}
            setFoundResidentialSubType={setFoundResidentialSubType}
            applyHandler={applyHandler}
            isOpenHouses={isOpenHouses}
            setIsOpenHouses={setIsOpenHouses}
            isOurListings={isOurListings}
            setIsOurListings={setIsOurListings}
            sqftMin={sqftMin}
            setSqftMin={setSqftMin}
            yearBuiltMin={yearBuiltMin}
            setYearBuiltMin={setYearBuiltMin}
            garagesSpacesMin={garagesSpacesMin}
            setGaragesSpacesMin={setGaragesSpacesMin}
            bathMin={bathMin}
            setBathMin={setBathMin}
            levelsMin={levelsMin}
            setLevelsMin={setLevelsMin}
            bedsMin={bedsMin}
            setBedsMin={setBedsMin}
            lotSizeAcresMin={lotSizeAcresMin}
            setLotSizeAcresMin={setLotSizeAcresMin}
            sqftMax={sqftMax}
            setSqftMax={setSqftMax}
            yearBuiltMax={yearBuiltMax}
            setYearBuiltMax={setYearBuiltMax}
            garagesSpacesMax={garagesSpacesMax}
            setGaragesSpacesMax={setGaragesSpacesMax}
            bathMax={bathMax}
            setBathMax={setBathMax}
            levelsMax={levelsMax}
            setLevelsMax={setLevelsMax}
            bedsMax={bedsMax}
            setBedsMax={setBedsMax}
            lotSizeAcresMax={lotSizeAcresMax}
            setLotSizeAcresMax={setLotSizeAcresMax}
            isPrimaryBedOnMain={isPrimaryBedOnMain}
            setIsPrimaryBedOnMain={setIsPrimaryBedOnMain}
            isFireplace={isFireplace}
            setIsFireplace={setIsFireplace}
            isAccessibilityFeatures={isAccessibilityFeatures}
            setIsAccessibilityFeatures={setIsAccessibilityFeatures}
            isPoolFeatures={isPoolFeatures}
            setIsPoolFeatures={setIsPoolFeatures}
            oneDay={oneDay}
            setOneDay={setOneDay}
            sevenDay={sevenDay}
            setSevenDay={setSevenDay}
            thirtyDay={thirtyDay}
            setThirtyDay={setThirtyDay}
            isCommingSoon={isCommingSoon}
            setIsCommingSoon={setIsCommingSoon}
            isActive={isActive}
            setIsActive={setIsActive}
            isActiveUnderContract={isActiveUnderContract}
            setIsActiveUnderContract={setIsActiveUnderContract}
            isPending={isPending}
            setIsPending={setIsPending}
            isClosed={isClosed}
            setIsClosed={setIsClosed}
            resetHandler={resetHandler}
            isOpenHousesUrl={isOpenHousesUrl}
            isFirstLoadedMinMaxQPar={isFirstLoadedMinMaxQPar}
            setIsFirstLoadedMinMaxQPar={setIsFirstLoadedMinMaxQPar}
            filterLocationRegions={filterLocationRegions}
            filterLocationMetros={filterLocationMetros}
            filterLocationCounties={filterLocationCounties}
            twCities={twCities}
            setTwCities={setTwCities}
            twPostals={twPostals}
            setTwPostals={setTwPostals}
            twCitiesPage={twCitiesPage}
            setTwCitiesPage={setTwCitiesPage}
            twPostalsPage={twPostalsPage}
            setTwPostalsPage={setTwPostalsPage}
            priceInfoLoading={priceInfoLoading}
            setPriceInfoLoading={setPriceInfoLoading}
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
          />
        </div>
        {/* <div id="new-wrapper-header-2"></div> */}
        {isMap.data === true && (
          <div
            className={
              isMap.data === true
                ? "new-wrapper-left-800-map"
                : "new-wrapper-left-800"
            }
          >
            <div
              style={{
                zIndex: 50,
                width: "100%",
                position: "relative",
              }}
              ref={mapBlockRef}
            >
              <Map
                sliderState={sliderState}
                currentPage={currentPage}
                filters={filters}
                sortingValue={sortingValue}
                mapZoom={mapZoom}
                setMapZoom={setMapZoom}
                currentCenter={currentCenter}
                setCurrentCenter={setCurrentCenter}
                isApply={isApply}
                polygonData={polygonData}
                setPolygonData={setPolygonData}
                polygonsData={polygonsData}
                setPolygonsData={setPolygonsData}
                currentPolygon={currentPolygon}
                setCurrentPolygon={setCurrentPolygon}
                currentPolygons={currentPolygons}
                setCurrentPolygons={setCurrentPolygons}
                subdomainData={subdomainData}
                getSwitchAnimation={getSwitchAnimation}
                currentMap={currentMap}
                setCurrentMap={setCurrentMap}
                currentMaps={currentMaps}
                setCurrentMaps={setCurrentMaps}
                dataInRectPage={dataInRectPage}
                setDataInRectPage={setDataInRectPage}
                state={state}
                setState={setState}
                screenSize={screenSize}
                currentLayersPolygon={currentLayersPolygon}
                setCurrentLayersPolygon={setCurrentLayersPolygon}
                currentLayersPolygons={currentLayersPolygons}
                setCurrentLayersPolygons={setCurrentLayersPolygons}
                isDraw={isDraw}
                setIsDraw={setIsDraw}
                setIsMultyDraw={setIsMultyDraw}
                isMultyDraw={isMultyDraw}
                dataInPolyline={dataInPolyline}
                setDataInPolyline={setDataInPolyline}
                dataInMultyPolygones={dataInMultyPolygones}
                setDataInMultyPolygones={setDataInMultyPolygones}
                drawCoordinates={drawCoordinates}
                setDrawCoordinates={setDrawCoordinates}
                currentCenterCoord={currentCenterCoord}
                setCurrentCenterCoord={setCurrentCenterCoord}
                setIsOurListings={setIsOurListings}
                isOurListings={isOurListings}
                applyHandler={applyHandler}
                setFilters={setFilters}
                twCities={twCities}
                twPostals={twPostals}
              />
            </div>
          </div>
        )}
        {isMap?.data === null && (
          <div
            id="new-wrapper-left"
            className={
              isMap.data === true
                ? "new-wrapper-left-map"
                : isMap.data === null
                ? "new-wrapper-left-both"
                : "new-wrapper-left"
            }
            style={{
              display: isMap?.data !== false ? "flex" : "none",
              visibility:
                isMap?.data === null &&
                isOpenNarrowFilters &&
                ((screenSize[0] > 900 && screenSize[0] <= 1200) ||
                  screenSize[0] > 1280)
                  ? "hidden"
                  : "visible",
              // width: "calc(100% - 500px)",
            }}
          >
            <div
              style={{
                zIndex: 50,
                width: isMap.data === null ? "calc(100% - 16px)" : "100%",
                position: "relative",
              }}
              ref={mapBlockRef}
            >
              <Map
                sliderState={sliderState}
                currentPage={currentPage}
                filters={filters}
                sortingValue={sortingValue}
                mapZoom={mapZoom}
                setMapZoom={setMapZoom}
                currentCenter={currentCenter}
                setCurrentCenter={setCurrentCenter}
                isApply={isApply}
                polygonData={polygonData}
                setPolygonData={setPolygonData}
                polygonsData={polygonsData}
                setPolygonsData={setPolygonsData}
                currentPolygon={currentPolygon}
                setCurrentPolygon={setCurrentPolygon}
                currentPolygons={currentPolygons}
                setCurrentPolygons={setCurrentPolygons}
                subdomainData={subdomainData}
                getSwitchAnimation={getSwitchAnimation}
                currentMap={currentMap}
                setCurrentMap={setCurrentMap}
                currentMaps={currentMaps}
                setCurrentMaps={setCurrentMaps}
                dataInRectPage={dataInRectPage}
                setDataInRectPage={setDataInRectPage}
                state={state}
                setState={setState}
                screenSize={screenSize}
                currentLayersPolygon={currentLayersPolygon}
                setCurrentLayersPolygon={setCurrentLayersPolygon}
                currentLayersPolygons={currentLayersPolygons}
                setCurrentLayersPolygons={setCurrentLayersPolygons}
                isDraw={isDraw}
                setIsDraw={setIsDraw}
                setIsMultyDraw={setIsMultyDraw}
                isMultyDraw={isMultyDraw}
                dataInPolyline={dataInPolyline}
                setDataInPolyline={setDataInPolyline}
                dataInMultyPolygones={dataInMultyPolygones}
                setDataInMultyPolygones={setDataInMultyPolygones}
                drawCoordinates={drawCoordinates}
                setDrawCoordinates={setDrawCoordinates}
                currentCenterCoord={currentCenterCoord}
                setCurrentCenterCoord={setCurrentCenterCoord}
                setIsOurListings={setIsOurListings}
                isOurListings={isOurListings}
                applyHandler={applyHandler}
                setFilters={setFilters}
                twCities={twCities}
                twPostals={twPostals}
              />
            </div>
          </div>
        )}
        <div
          id="new-wrapper-right"
          style={{
            width:
              isMap?.data === false
                ? "100%"
                : isMap?.data === null
                ? "calc(40% - 20px)"
                : "0px",
          }}
          className={
            isMap.data === true
              ? "new-wrapper-right-map"
              : isMap.data === null
              ? "new-wrapper-right-both"
              : "new-wrapper-right"
          }
        >
          <div>
            {/* {(houseCardDataInRect?.data?.listings ||
              (isMap.data === false && visibleHouseCards)) && (
              <div
                id="new-wrapper-top"
                className={
                  isMap.data === false
                    ? "new-wrapper-top-list"
                    : "new-wrapper-top"
                }
              >
                <div
                  id="sorting-block-input-wrapper"
                  className="sorting-block-header-wrapper-block"
                >
                  <div
                    className="sorting-block-header-wrapper-block-2"
                    style={{ display: isMap.data !== false ? "none" : "flex" }}
                  >
                    <BottomHeaderPaginationComponent
                      visibleHouseCards={visibleHouseCards}
                      topListings={topListings}
                      currentPage={currentPage}
                      data={data}
                      houseCardDataInRect={houseCardDataInRect}
                      dataInRectPage={dataInRectPage}
                      screenSize={screenSize}
                      sortingValue={sortingValue}
                      setDataInRectPage={setDataInRectPage}
                      getSwitchAnimation={getSwitchAnimation}
                      state={state}
                      setState={setState}
                      onIconClick={onIconClick}
                      isActive1={isActive1}
                      isActive2={isActive2}
                      openNarrowFiltersHandle={openNarrowFiltersHandle}
                      filters={filters}
                      setFilters={setFilters}
                      isOurListings={isOurListings}
                      setIsOurListings={setIsOurListings}
                      applyHandler={applyHandler}
                      setMapZoom={setMapZoom}
                      mapZoom={mapZoom}
                      isAllOurListings={isAllOurListings}
                      setIsAllOurListings={setIsAllOurListings}
                    />
                  </div>
                  <div
                    className={
                      isMap.data !== false
                        ? "sorting-block-header-wrapper-block-1"
                        : "sorting-block-header-wrapper-block-1-list"
                    }
                  >
                    <SortingInputBlock
                      filters={filters}
                      sortingValue={sortingValue}
                      setSortingValue={setSortingValue}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      dataInRectPage={dataInRectPage}
                      setDataInRectPage={setDataInRectPage}
                      isDraw={isDraw}
                      isMultyDraw={isMultyDraw}
                      dataInPolyline={dataInPolyline}
                      setDataInPolyline={setDataInPolyline}
                      dataInMultyPolygones={dataInMultyPolygones}
                      setDataInMultyPolygones={setDataInMultyPolygones}
                      drawCoordinates={drawCoordinates}
                      setDrawCoordinates={setDrawCoordinates}
                    />
                  </div>
                </div>
                <div
                  className={
                    isMap.data !== false
                      ? "new-bottomHeaderPaginationComponent-wrapper"
                      : "new-bottomHeaderPaginationComponent-wrapper-list"
                  }
                >
                  <BottomHeaderPaginationComponent
                    visibleHouseCards={visibleHouseCards}
                    topListings={topListings}
                    currentPage={currentPage}
                    data={data}
                    houseCardDataInRect={houseCardDataInRect}
                    dataInRectPage={dataInRectPage}
                    screenSize={screenSize}
                    sortingValue={sortingValue}
                    setDataInRectPage={setDataInRectPage}
                    getSwitchAnimation={getSwitchAnimation}
                    state={state}
                    setState={setState}
                    onIconClick={onIconClick}
                    isActive1={isActive1}
                    isActive2={isActive2}
                    openNarrowFiltersHandle={openNarrowFiltersHandle}
                    filters={filters}
                    setFilters={setFilters}
                    isOurListings={isOurListings}
                    setIsOurListings={setIsOurListings}
                    applyHandler={applyHandler}
                    setMapZoom={setMapZoom}
                    mapZoom={mapZoom}
                    isAllOurListings={isAllOurListings}
                    setIsAllOurListings={setIsAllOurListings}
                  />
                  {isMap.data === null && visibleHouseCards?.length > 0 && (
                    <div
                      id="filters-page-house-cards-block-wrapper-displaying-listings"
                      className="sorting-block-header-wrapper-block-displaying-listings filters-page-house-cards-block-wrapper-displaying-listings-showing-page"
                    >
                      <div>
                        {houseCardDataInRect?.data?.listings?.length > 0 && (
                          <span>
                            Page {dataInRectPage?.value} of{" "}
                            {Math.ceil(
                              houseCardDataInRect?.data.total /
                                houseCardDataInRect?.data?.perpage
                            ).toLocaleString("en-US")}
                          </span>
                        )}
                        {data?.total && (
                          <span>
                            {" "}
                            (Showing{" "}
                            {isMap.data === null &&
                            houseCardDataInRect?.data?.listings?.length > 0
                              ? houseCardDataInRect?.data?.listings?.length
                              : visibleHouseCards?.length}{" "}
                            of{" "}
                            {isMap.data === null &&
                            houseCardDataInRect?.data?.listings?.length > 0
                              ? houseCardDataInRect?.data.total.toLocaleString(
                                  "en-US"
                                )
                              : data?.total.toLocaleString("en-US")}{" "}
                            Listings)
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="new-bottomHeaderPaginationComponent-wrapper-800"
                  style={{
                    display:
                      isMap.data === null && screenSize[0] >= 1080
                        ? "flex"
                        : "none",
                  }}
                >
                  <BottomHeaderPaginationComponent
                    visibleHouseCards={visibleHouseCards}
                    topListings={topListings}
                    currentPage={currentPage}
                    data={data}
                    houseCardDataInRect={houseCardDataInRect}
                    dataInRectPage={dataInRectPage}
                    screenSize={screenSize}
                    sortingValue={sortingValue}
                    setDataInRectPage={setDataInRectPage}
                    getSwitchAnimation={getSwitchAnimation}
                    state={state}
                    setState={setState}
                    onIconClick={onIconClick}
                    isActive1={isActive1}
                    isActive2={isActive2}
                    openNarrowFiltersHandle={openNarrowFiltersHandle}
                    filters={filters}
                    setFilters={setFilters}
                    isOurListings={isOurListings}
                    setIsOurListings={setIsOurListings}
                    applyHandler={applyHandler}
                    setMapZoom={setMapZoom}
                    mapZoom={mapZoom}
                    isAllOurListings={isAllOurListings}
                    setIsAllOurListings={setIsAllOurListings}
                  />
                </div>
              </div>
            )} */}
            <div
              id="new-wrapper-main"
              className={
                isMap.data === false
                  ? "new-wrapper-main-800"
                  : isMap.data === null
                  ? "new-wrapper-main-both"
                  : ""
              }
            >
              {false && (
                <div className="new-filters-page-block-content-left">
                  <FiltersBlock
                    filters={filters}
                    setFilters={setFilters}
                    setIsApply={setIsApply}
                    isApply={isApply}
                    sortingValue={sortingValue}
                    residential={residential}
                    setResidential={setResidential}
                    residentialLease={residentialLease}
                    setResidentialLease={setResidentialLease}
                    ResidentialIncome={ResidentialIncome}
                    setResidentialIncome={setResidentialIncome}
                    commercialSale={commercialSale}
                    setCommercialSale={setCommercialSale}
                    commercialLease={commercialLease}
                    setCommercialLease={setCommercialLease}
                    farmAndRanch={farmAndRanch}
                    setFarmAndRanch={setFarmAndRanch}
                    land={land}
                    setLand={setLand}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    MLS_address={MLS_address}
                    MLS_addressRes={MLS_addressRes}
                    foundMLS_address={foundMLS_address}
                    setMLS_Address={setMLS_Address}
                    setMLS_AddressRes={setMLS_AddressRes}
                    setFoundMLS_address={setFoundMLS_address}
                    region={region}
                    metro={metro}
                    country={country}
                    city={city}
                    postal={postal}
                    setRegion={setRegion}
                    setMetro={setMetro}
                    setCountry={setCountry}
                    setCity={setCity}
                    setPostal={setPostal}
                    setFoundRegions={setFoundRegions}
                    setFoundMetro={setFoundMetro}
                    setFoundCountry={setFoundCountry}
                    setFoundCity={setFoundCity}
                    setFoundPostal={setFoundPostal}
                    foundRegions={foundRegions}
                    foundMetro={foundMetro}
                    foundCountry={foundCountry}
                    foundCity={foundCity}
                    foundPostal={foundPostal}
                    allPropertyTypes={allPropertyTypes}
                    allSubPropertyTypes={allSubPropertyTypes}
                    setAllPropertyTypes={setAllPropertyTypes}
                    residentialSubType={residentialSubType}
                    setResidentialSubType={setResidentialSubType}
                    foundResidentialSubType={foundResidentialSubType}
                    setFoundResidentialSubType={setFoundResidentialSubType}
                    applyHandler={applyHandler}
                    isOpenHouses={isOpenHouses}
                    setIsOpenHouses={setIsOpenHouses}
                    isOurListings={isOurListings}
                    setIsOurListings={setIsOurListings}
                    sqftMin={sqftMin}
                    setSqftMin={setSqftMin}
                    yearBuiltMin={yearBuiltMin}
                    setYearBuiltMin={setYearBuiltMin}
                    garagesSpacesMin={garagesSpacesMin}
                    setGaragesSpacesMin={setGaragesSpacesMin}
                    bathMin={bathMin}
                    setBathMin={setBathMin}
                    levelsMin={levelsMin}
                    setLevelsMin={setLevelsMin}
                    bedsMin={bedsMin}
                    setBedsMin={setBedsMin}
                    lotSizeAcresMin={lotSizeAcresMin}
                    setLotSizeAcresMin={setLotSizeAcresMin}
                    sqftMax={sqftMax}
                    setSqftMax={setSqftMax}
                    yearBuiltMax={yearBuiltMax}
                    setYearBuiltMax={setYearBuiltMax}
                    garagesSpacesMax={garagesSpacesMax}
                    setGaragesSpacesMax={setGaragesSpacesMax}
                    bathMax={bathMax}
                    setBathMax={setBathMax}
                    levelsMax={levelsMax}
                    setLevelsMax={setLevelsMax}
                    bedsMax={bedsMax}
                    setBedsMax={setBedsMax}
                    lotSizeAcresMax={lotSizeAcresMax}
                    setLotSizeAcresMax={setLotSizeAcresMax}
                    isPrimaryBedOnMain={isPrimaryBedOnMain}
                    setIsPrimaryBedOnMain={setIsPrimaryBedOnMain}
                    isFireplace={isFireplace}
                    setIsFireplace={setIsFireplace}
                    isAccessibilityFeatures={isAccessibilityFeatures}
                    setIsAccessibilityFeatures={setIsAccessibilityFeatures}
                    isPoolFeatures={isPoolFeatures}
                    setIsPoolFeatures={setIsPoolFeatures}
                    oneDay={oneDay}
                    setOneDay={setOneDay}
                    sevenDay={sevenDay}
                    setSevenDay={setSevenDay}
                    thirtyDay={thirtyDay}
                    setThirtyDay={setThirtyDay}
                    isCommingSoon={isCommingSoon}
                    setIsCommingSoon={setIsCommingSoon}
                    isActive={isActive}
                    setIsActive={setIsActive}
                    isActiveUnderContract={isActiveUnderContract}
                    setIsActiveUnderContract={setIsActiveUnderContract}
                    isPending={isPending}
                    setIsPending={setIsPending}
                    isClosed={isClosed}
                    setIsClosed={setIsClosed}
                    resetHandler={resetHandler}
                    isOpenHousesUrl={isOpenHousesUrl}
                    isFirstLoadedMinMaxQPar={isFirstLoadedMinMaxQPar}
                    setIsFirstLoadedMinMaxQPar={setIsFirstLoadedMinMaxQPar}
                    filterLocationRegions={filterLocationRegions}
                    filterLocationMetros={filterLocationMetros}
                    filterLocationCounties={filterLocationCounties}
                    twCities={twCities}
                    setTwCities={setTwCities}
                    twPostals={twPostals}
                    setTwPostals={setTwPostals}
                    twCitiesPage={twCitiesPage}
                    setTwCitiesPage={setTwCitiesPage}
                    twPostalsPage={twPostalsPage}
                    setTwPostalsPage={setTwPostalsPage}
                    priceInfoLoading={priceInfoLoading}
                    setPriceInfoLoading={setPriceInfoLoading}
                    dateFrom={dateFrom}
                    setDateFrom={setDateFrom}
                    dateTo={dateTo}
                    setDateTo={setDateTo}
                  />
                </div>
              )}
              <div className="new-SortingBlock-wrapper">
                <SortingBlock
                  filters={filters}
                  isApply={isApply}
                  container={container}
                  sortingValue={sortingValue}
                  setSortingValue={setSortingValue}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  isActive1={isActive1}
                  isActive2={isActive2}
                  isActive3={isActive3}
                  isPicturesFeature={isPicturesFeature}
                  dataInRectPage={dataInRectPage}
                  setDataInRectPage={setDataInRectPage}
                  isFirstLoadedMinMaxQPar={isFirstLoadedMinMaxQPar}
                  queryParamsObject={queryParamsObject}
                  dateFrom={dateFrom}
                  setDateFrom={setDateFrom}
                  dateTo={dateTo}
                  setDateTo={setDateTo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltersPageBlock;

const FiltersBlockNarrow = ({
  filters,
  setFilters,
  setIsApply,
  isApply,
  sortingValue,
  residential,
  setResidential,
  residentialLease,
  setResidentialLease,
  ResidentialIncome,
  setResidentialIncome,
  commercialSale,
  setCommercialSale,
  commercialLease,
  setCommercialLease,
  farmAndRanch,
  setFarmAndRanch,
  land,
  setLand,
  setCurrentPage,
  currentPage,
  MLS_address,
  MLS_addressRes,
  foundMLS_address,
  setMLS_Address,
  setMLS_AddressRes,
  setFoundMLS_address,
  region,
  metro,
  country,
  city,
  postal,
  setRegion,
  setMetro,
  setCountry,
  setCity,
  setPostal,
  setFoundRegions,
  setFoundMetro,
  setFoundCountry,
  setFoundCity,
  setFoundPostal,
  foundRegions,
  foundMetro,
  foundCountry,
  foundCity,
  foundPostal,
  allPropertyTypes,
  allSubPropertyTypes,
  setAllPropertyTypes,
  residentialSubType,
  setResidentialSubType,
  foundResidentialSubType,
  setFoundResidentialSubType,
  applyHandler,
  isOpenHouses,
  setIsOpenHouses,
  isOurListings,
  setIsOurListings,
  sqftMin,
  setSqftMin,
  yearBuiltMin,
  setYearBuiltMin,
  garagesSpacesMin,
  setGaragesSpacesMin,
  bathMin,
  setBathMin,
  levelsMin,
  setLevelsMin,
  bedsMin,
  setBedsMin,
  lotSizeAcresMin,
  setLotSizeAcresMin,
  sqftMax,
  setSqftMax,
  yearBuiltMax,
  setYearBuiltMax,
  garagesSpacesMax,
  setGaragesSpacesMax,
  bathMax,
  setBathMax,
  levelsMax,
  setLevelsMax,
  bedsMax,
  setBedsMax,
  lotSizeAcresMax,
  setLotSizeAcresMax,
  isPrimaryBedOnMain,
  setIsPrimaryBedOnMain,
  isFireplace,
  setIsFireplace,
  isAccessibilityFeatures,
  setIsAccessibilityFeatures,
  isPoolFeatures,
  setIsPoolFeatures,
  oneDay,
  setOneDay,
  sevenDay,
  setSevenDay,
  thirtyDay,
  setThirtyDay,
  isCommingSoon,
  setIsCommingSoon,
  isActive,
  setIsActive,
  isActiveUnderContract,
  setIsActiveUnderContract,
  isPending,
  setIsPending,
  isClosed,
  setIsClosed,
  resetHandler,
  isOpenHousesUrl,
  isFirstLoadedMinMaxQPar,
  setIsFirstLoadedMinMaxQPar,
  filterLocationRegions,
  filterLocationMetros,
  filterLocationCounties,
  twCities,
  setTwCities,
  twPostals,
  setTwPostals,
  twCitiesPage,
  setTwCitiesPage,
  twPostalsPage,
  setTwPostalsPage,
  priceInfoLoading,
  setPriceInfoLoading,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
}) => {
  const { isReset } = useSelector((state) => state.isResetFilters);

  const dispatch = useDispatch();

  const isNarrowFiltersCloseHandle = () => {
    dispatch(updateIsOpenNarrowFilters(false));
    window.scrollTo(0, 0);
  };

  const screenSize = useScreenSize();

  useEffect(() => {
    if (screenSize[0] > 1199) isNarrowFiltersCloseHandle();
  }, [screenSize[0]]);

  const [isExpandedPropertyType, setIsExpandedPropertyType] = useState(true);

  const onTittlePropertyTypeHandle = () => {
    setIsExpandedPropertyType(!isExpandedPropertyType);
  };

  return (
    <>
      <div id="filters-page-block-filter-block-narrow-top-buttons-block">
        <div></div>
        <div onClick={isNarrowFiltersCloseHandle}>
          <CloseIcon color={colors.mainBg} />
        </div>
      </div>
      <form method="dialog" id="filters-block-wrapper-form-narrow">
        <QuickSearch
          filters={filters}
          setFilters={setFilters}
          sortingValue={sortingValue}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          MLS_address={MLS_address}
          MLS_addressRes={MLS_addressRes}
          foundMLS_address={foundMLS_address}
          setMLS_Address={setMLS_Address}
          setMLS_AddressRes={setMLS_AddressRes}
          setFoundMLS_address={setFoundMLS_address}
        />
        <div
          className={
            isExpandedPropertyType
              ? "filters-page-block-filter-block-narrow-propertyTypes"
              : "collapsed-filter-propertyTypes-subTypes"
          }
        >
          <PropertyType
            filters={filters}
            setFilters={setFilters}
            residential={residential}
            setResidential={setResidential}
            residentialLease={residentialLease}
            setResidentialLease={setResidentialLease}
            ResidentialIncome={ResidentialIncome}
            setResidentialIncome={setResidentialIncome}
            commercialSale={commercialSale}
            setCommercialSale={setCommercialSale}
            commercialLease={commercialLease}
            setCommercialLease={setCommercialLease}
            farmAndRanch={farmAndRanch}
            setFarmAndRanch={setFarmAndRanch}
            land={land}
            setLand={setLand}
            isReset={isReset}
            allPropertyTypes={allPropertyTypes}
            allSubPropertyTypes={allSubPropertyTypes}
            setAllPropertyTypes={setAllPropertyTypes}
            residentialSubType={residentialSubType}
            setResidentialSubType={setResidentialSubType}
            onTittleHandle={onTittlePropertyTypeHandle}
            isExpanded={isExpandedPropertyType}
          />
          <PropertySubType
            isReset={isReset}
            allPropertyTypes={allPropertyTypes}
            allSubPropertyTypes={allSubPropertyTypes}
            residentialSubType={residentialSubType}
            setResidentialSubType={setResidentialSubType}
            foundResidentialSubType={foundResidentialSubType}
            setFoundResidentialSubType={setFoundResidentialSubType}
            filters={filters}
            setFilters={setFilters}
            isExpanded={isExpandedPropertyType}
          />
        </div>
        <div className="filters-page-block-filter-block-narrow-FL-HF-wrapper">
          <FilterLocation
            filters={filters}
            setFilters={setFilters}
            sortingValue={sortingValue}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            MLS_address={MLS_address}
            MLS_addressRes={MLS_addressRes}
            setMLS_Address={setMLS_Address}
            setMLS_AddressRes={setMLS_AddressRes}
            setFoundMLS_address={setFoundMLS_address}
            region={region}
            metro={metro}
            country={country}
            city={city}
            postal={postal}
            setRegion={setRegion}
            setMetro={setMetro}
            setCountry={setCountry}
            setCity={setCity}
            setPostal={setPostal}
            setFoundRegions={setFoundRegions}
            setFoundMetro={setFoundMetro}
            setFoundCountry={setFoundCountry}
            setFoundCity={setFoundCity}
            setFoundPostal={setFoundPostal}
            foundRegions={foundRegions}
            foundMetro={foundMetro}
            foundCountry={foundCountry}
            foundCity={foundCity}
            foundPostal={foundPostal}
            filterLocationRegions={filterLocationRegions}
            filterLocationMetros={filterLocationMetros}
            filterLocationCounties={filterLocationCounties}
            twCities={twCities}
            setTwCities={setTwCities}
            twPostals={twPostals}
            setTwPostals={setTwPostals}
            twCitiesPage={twCitiesPage}
            setTwCitiesPage={setTwCitiesPage}
            twPostalsPage={twPostalsPage}
            setTwPostalsPage={setTwPostalsPage}
          />
          <HomeFeatures
            filters={filters}
            setFilters={setFilters}
            sqftMin={sqftMin}
            setSqftMin={setSqftMin}
            yearBuiltMin={yearBuiltMin}
            setYearBuiltMin={setYearBuiltMin}
            garagesSpacesMin={garagesSpacesMin}
            setGaragesSpacesMin={setGaragesSpacesMin}
            bathMin={bathMin}
            setBathMin={setBathMin}
            levelsMin={levelsMin}
            setLevelsMin={setLevelsMin}
            bedsMin={bedsMin}
            setBedsMin={setBedsMin}
            lotSizeAcresMin={lotSizeAcresMin}
            setLotSizeAcresMin={setLotSizeAcresMin}
            sqftMax={sqftMax}
            setSqftMax={setSqftMax}
            yearBuiltMax={yearBuiltMax}
            setYearBuiltMax={setYearBuiltMax}
            garagesSpacesMax={garagesSpacesMax}
            setGaragesSpacesMax={setGaragesSpacesMax}
            bathMax={bathMax}
            setBathMax={setBathMax}
            levelsMax={levelsMax}
            setLevelsMax={setLevelsMax}
            bedsMax={bedsMax}
            setBedsMax={setBedsMax}
            lotSizeAcresMax={lotSizeAcresMax}
            setLotSizeAcresMax={setLotSizeAcresMax}
            isPrimaryBedOnMain={isPrimaryBedOnMain}
            setIsPrimaryBedOnMain={setIsPrimaryBedOnMain}
            isFireplace={isFireplace}
            setIsFireplace={setIsFireplace}
            isAccessibilityFeatures={isAccessibilityFeatures}
            setIsAccessibilityFeatures={setIsAccessibilityFeatures}
            isPoolFeatures={isPoolFeatures}
            setIsPoolFeatures={setIsPoolFeatures}
            isFirstLoadedMinMaxQPar={isFirstLoadedMinMaxQPar}
            setIsFirstLoadedMinMaxQPar={setIsFirstLoadedMinMaxQPar}
            priceInfoLoading={priceInfoLoading}
            setPriceInfoLoading={setPriceInfoLoading}
          />
        </div>
        <div id="filters-block-wrapper-form-narrow-bottom-checkboxes">
          <Status
            filters={filters}
            setFilters={setFilters}
            isCommingSoon={isCommingSoon}
            setIsCommingSoon={setIsCommingSoon}
            isActive={isActive}
            setIsActive={setIsActive}
            isActiveUnderContract={isActiveUnderContract}
            setIsActiveUnderContract={setIsActiveUnderContract}
            isPending={isPending}
            setIsPending={setIsPending}
            isClosed={isClosed}
            setIsClosed={setIsClosed}
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
          />
          <Timeframe
            filters={filters}
            setFilters={setFilters}
            oneDay={oneDay}
            setOneDay={setOneDay}
            sevenDay={sevenDay}
            setSevenDay={setSevenDay}
            thirtyDay={thirtyDay}
            setThirtyDay={setThirtyDay}
          />
          <ShowOnly
            filters={filters}
            setFilters={setFilters}
            isOpenHouses={isOpenHouses}
            setIsOpenHouses={setIsOpenHouses}
            isOurListings={isOurListings}
            setIsOurListings={setIsOurListings}
            isOpenHousesUrl={isOpenHousesUrl}
          />
        </div>
        <ButtonsBlock
          filters={filters}
          setFilters={setFilters}
          setIsApply={setIsApply}
          isApply={isApply}
          sortingValue={sortingValue}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          applyHandler={applyHandler}
          resetHandler={resetHandler}
          priceInfoLoading={priceInfoLoading}
        />
      </form>
    </>
  );
};

const BottomHeaderPaginationComponent = ({
  visibleHouseCards,
  topListings,
  currentPage,
  data,
  houseCardDataInRect,
  dataInRectPage,
  screenSize,
  sortingValue,
  setDataInRectPage,
  getSwitchAnimation,
  state,
  setState,
  onIconClick,
  isActive1,
  isActive2,
  openNarrowFiltersHandle,
  filters,
  setFilters,
  isOurListings,
  setIsOurListings,
  applyHandler,
  setMapZoom,
  mapZoom,
  setIsAllOurListings,
  isAllOurListings,
}) => {
  const isMap = useSelector((state) => state.isMap);

  const handleBothViewPageClick = (event) => {
    const currentMapViewPage = mokedMapViewPagesList.filter(
      (item) => item.value === event.selected + 1
    )[0];
    setDataInRectPage(currentMapViewPage);
  };

  const [currentZoomMap, setCurrentZoomMap] = useState(null);
  const [isOurListingsClicked, setIsOurListingsClicked] = useState(false);
  const [isAllOurListingsClicked, setIsAllOurListingsClicked] = useState(false);
  // const [isAllOurListings, setIsAllOurListings] = useState(null);

  const ourListingsBaseHandle = () => {
    setIsAllOurListings(!isAllOurListings);
  };

  useEffect(() => {
    if (isAllOurListings) {
      ourListingsHandle();
    }
    if (isAllOurListings === false) {
      ourAllListingsHandle();
    }
  }, [isAllOurListings]);

  const ourListingsHandle = () => {
    setIsOurListingsClicked(true);

    setIsOurListings(true);
    setCurrentZoomMap(mapZoom);
    setMapZoom(6);
    // setZoomToUrl(6);
    setFilters({
      ...filters,
      showOnly: {
        ...filters.showOnly,
        ourListings: true,
      },
      map: {
        ...filters.map,
        zoom: 6,
      },
    });
  };

  const ourAllListingsHandle = () => {
    setIsAllOurListingsClicked(true);

    if (currentZoomMap) {
      setMapZoom(currentZoomMap);
    }
    setIsOurListings(false);
    setFilters({
      ...filters,
      showOnly: {
        ...filters.showOnly,
        ourListings: false,
      },
      map: {
        ...filters.map,
        zoom: currentZoomMap ? currentZoomMap : null,
      },
    });
  };

  useEffect(() => {
    if (
      isOurListingsClicked &&
      isOurListings &&
      filters?.showOnly?.ourListings
    ) {
      applyHandler();
      setIsOurListingsClicked(false);
    }
  }, [isOurListingsClicked, filters?.showOnly?.ourListings, isOurListings]);

  useEffect(() => {
    if (
      isAllOurListingsClicked &&
      !filters?.showOnly?.ourListings &&
      filters?.map?.zoom === currentZoomMap
    ) {
      applyHandler();
      setIsAllOurListingsClicked(false);
    }
  }, [
    isAllOurListingsClicked,
    filters?.showOnly?.ourListings,
    filters?.map?.zoom,
  ]);

  return (
    <>
      {((isMap.data !== true &&
        houseCardDataInRect?.data?.listings?.length > 0) ||
        isMap.data === false) && (
        <div
          id="filters-page-house-cards-block-wrapper-displaying-listings"
          className="sorting-block-header-wrapper-block-displaying-listings filters-page-house-cards-block-wrapper-displaying-listings399"
          style={{ top: topListings }}
        >
          <div className="new-filters-page-house-cards-block-wrapper-displaying-listings-wrapper">
            {isMap.data === false && data.total === 0 && (
              <>
                <span className="new-filters-page-house-cards-block-wrapper-displaying-listings-span">
                  Showing no page
                </span>
                <span className="new-filters-page-house-cards-block-wrapper-displaying-listings-span"></span>
              </>
            )}
            {isMap.data === false && visibleHouseCards?.length > 0 && (
              <div
                id="filters-page-house-cards-block-wrapper-displaying-listings"
                className="sorting-block-header-wrapper-block-displaying-listings"
                style={{ top: topListings }}
              >
                <div>
                  {data?.total && isMap.data === false && (
                    <span>
                      Page {currentPage + 1} of{" "}
                      {Math.ceil(data?.total / data?.perpage).toLocaleString(
                        "en-US"
                      )}
                    </span>
                  )}
                  {isMap.data === null &&
                    houseCardDataInRect?.data?.listings?.length > 0 && (
                      <span>
                        Page {dataInRectPage?.value} of{" "}
                        {Math.ceil(
                          houseCardDataInRect?.data.total /
                            houseCardDataInRect?.data?.perpage
                        ).toLocaleString("en-US")}
                      </span>
                    )}
                  {data?.total && (
                    /* (screenSize[0] < 1200 || screenSize[0] > 1350) && ( */
                    <span>
                      {" "}
                      (Showing{" "}
                      {isMap.data === null &&
                      houseCardDataInRect?.data?.listings?.length > 0
                        ? houseCardDataInRect?.data?.listings?.length
                        : visibleHouseCards?.length}{" "}
                      of{" "}
                      {isMap.data === null &&
                      houseCardDataInRect?.data?.listings?.length > 0
                        ? houseCardDataInRect?.data.total.toLocaleString(
                            "en-US"
                          )
                        : data?.total.toLocaleString("en-US")}{" "}
                      Listings)
                    </span>
                  )}
                </div>
              </div>
            )}
            {/* {isMap.data === null &&
              houseCardDataInRect?.isLoading === false &&
              houseCardDataInRect?.data?.listings?.length && (
                <SortingHeaderPagination
                  dataInRectPage={dataInRectPage}
                  setDataInRectPage={setDataInRectPage}
                  houseCardData={houseCardDataInRect.data}
                  sortingValue={sortingValue}
                  screenWidth={screenSize[0]}
                />
              )} */}
            {isMap.data === null &&
              houseCardDataInRect?.isLoading === false &&
              houseCardDataInRect?.data?.total > 100 && (
                <Pagination
                  currentPage={dataInRectPage.value - 1}
                  initialPage={dataInRectPage.value - 1}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  pageCount={Math.ceil(
                    houseCardDataInRect?.data?.total /
                      houseCardDataInRect?.data?.perpage
                  )}
                  onPageChange={handleBothViewPageClick}
                  maxPageCount={600}
                />
              )}
            {isMap.data === null &&
              houseCardDataInRect?.isLoading === false &&
              houseCardDataInRect?.data?.listings?.length && (
                <div
                  style={{ display: "none" }}
                  className="sorting-block-header-wrapper-block-displaying-listings-showing"
                >
                  {" "}
                  (Showing{" "}
                  {isMap.data === null &&
                  houseCardDataInRect?.data?.listings?.length > 0
                    ? houseCardDataInRect?.data?.listings?.length
                    : visibleHouseCards?.length}{" "}
                  of{" "}
                  {isMap.data === null &&
                  houseCardDataInRect?.data?.listings?.length
                    ? houseCardDataInRect?.data.total.toLocaleString("en-US")
                    : data?.total.toLocaleString("en-US")}{" "}
                  Listings)
                </div>
              )}
          </div>
          {/* {isMap.data === null &&
            !(
              houseCardDataInRect?.isLoading === false &&
              houseCardDataInRect?.data?.listings?.length
            ) && (
              <div className="new-filters-page-house-cards-block-wrapper-displaying-listings-wrapper-loading">
                Loading...
              </div>
            )} */}
          {/* <SortngHeaderPagination dataInRectPage={dataInRectPage}
            setDataInRectPage={setDataInRectPage} houseCardData={houseCardDataInRect.data} sortingValue={sortingValue}/> */}
        </div>
      )}
      {isMap.data === null && !houseCardDataInRect?.data?.listings?.length && (
        <div
          id="filters-page-house-cards-block-wrapper-displaying-listings"
          className="sorting-block-header-wrapper-block-displaying-listings displaying-listings-loading"
          style={{ top: topListings }}
        >
          <div>
            <span>Loading...</span>
          </div>
        </div>
      )}
      <div
        className={
          isMap.data === false
            ? "new-sorting-block-btn-wrapper new-sorting-block-btn-wrapper-list"
            : "new-sorting-block-btn-wrapper new-sorting-block-btn-wrapper-both"
        }
      >
        <div className="sorting-block-icon-map-list">
          <TripleSwitcherSliderButton
            getSwitchAnimation={getSwitchAnimation}
            state={state}
            setState={setState}
          />
        </div>
        {/* // TODO THIS WRAPPER SHOULD BE ROMOVED  */}

        <div style={{ display: "none" }}>
          {/* ////// FILTERS BTN //////// */}
          <div
            id="sorting-block-icon-1_5"
            onClick={() => onIconClick("icon_8")}
          >
            <div className="sorting-block-icon-1_5-tooltip">
              <TooltipMessage content="Large" />
            </div>
            <div style={{ display: "flex", justifyAlign: "center", width: 27 }}>
              {/* <CubeIcon color={isActive2 ? colors.blue : colors.mainBg} />
            <CubeIcon color={isActive2 ? colors.blue : colors.mainBg} /> */}
              {/* <CubeIconBig4 color={isActive2 ? colors.blue : colors.mainBg} /> */}
              <CubeIcon color={isActive2 ? colors.blue : colors.mainBg} />
            </div>
          </div>
          <div id="sorting-block-icon-1" onClick={() => onIconClick("icon_4")}>
            <div className="sorting-block-icon-1-tooltip">
              <TooltipMessage content="Classic" />
            </div>
            {/* <CubeIcon
            color={isActive1 || isActive2 ? colors.mainBg : colors.blue}
          /> */}
            <CubeIconBig4
              color={isActive1 || isActive2 ? colors.mainBg : colors.blue}
            />
          </div>
          <div
            id="sorting-block-icon-2"
            onClick={() => onIconClick("icon_list")}
          >
            <div className="sorting-block-icon-2-tooltip">
              <TooltipMessage content="List" />
            </div>
            <ListIcon
              color={isActive1 && !isActive2 ? colors.blue : colors.mainBg}
            />
          </div>
        </div>
        <div id="sorting-block-icon-2_5" onClick={ourListingsBaseHandle}>
          <div
            className={
              isAllOurListings
                ? "sorting-block-icon-2_5-tooltip-all"
                : "sorting-block-icon-2_5-tooltip"
            }
          >
            <TooltipMessage
              content={isAllOurListings ? "All Listings" : "Our Listings"}
            />
          </div>
          <div className="sorting-block-icon-2_5-our-listings">
            <div className="sorting-block-icon-2_5-our-listings-realtytexas-icon"></div>
            <div className="sorting-block-icon-2_5-our-listings-content">
              {isAllOurListings ? "All Listings" : "Listings"}
            </div>
          </div>
        </div>
        <div id="sorting-block-icon-3" onClick={openNarrowFiltersHandle}>
          <div className="sorting-block-icon-3-filters">Filters</div>
        </div>
        {/* ////// FILTERS BTN //////// */}
      </div>
    </>
  );
};
